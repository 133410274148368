import React from 'react';
import { withRouter } from 'react-router-dom'

import Proficiency from '../../Proficiency';

import './ReflectionJournalEntry.css';

class ReflectionJournalEntry extends React.Component {
  getScoreColor(score) {
    if (score > 7) {
      return 'green';
    } else if (score < 5) {
      return 'red';
    } else {
      return 'grey';
    }
  }

  handleEdit = () => {
    this.props.history.push(`/me/reflection/edit?date=${this.props.entry.date}&index=${this.props.index}`);
    window.location.reload();
    window.scroll(0,0);
  }

  renderScore() {
    if (!this.props.entry.score) return "";

    return <span className='label-normal' style={{backgroundColor: this.getScoreColor(this.props.entry.score), float: 'right'}}>{this.props.entry.score}/10 &nbsp;&nbsp;</span>
  }

  renderSkill() {
    if (!this.props.entry.skillId) return "";

    return (
      <div>
        <Proficiency user={this.props.user} skillId={this.props.entry.skillId} />
        <span>
          &nbsp;
          <strong>
            <a href={`/skill/${this.props.skills[this.props.entry.skillId].name}`}>{this.props.skills[this.props.entry.skillId].name}</a>
          </strong>
        </span>
      </div>
    )
  }

  renderProperty(label, value) {
    if (!value) return "";

    return (
      <div>
        <h4>{label}</h4>
        <p>{value}</p>
      </div>
    )
  }

  render() {
    return (
      <div className="journal-entry">
        {this.renderScore()}
        {this.renderSkill()}
        {this.renderProperty("What went right?", this.props.entry.right)}
        {this.renderProperty("What went wrong?", this.props.entry.wrong)}
        {this.renderProperty("How can I improve?", this.props.entry.improve)}
        {this.renderProperty("What lessons have I learned?", this.props.entry.lessons)}
        {this.renderProperty("What would I do differently next time?", this.props.entry.different)}
        {this.renderProperty("What should I do next?", this.props.entry.next)}
        {this.renderProperty("Notes", this.props.entry.notes)}
        <button className="bg-orange" onClick={this.handleEdit.bind(this)}>Edit</button>&nbsp;
        <button onClick={this.props.handleDeleteEntry.bind(this, this.props.entry)}>Delete</button>
      </div>
    )
  }
}

export default withRouter(ReflectionJournalEntry);