import React from 'react';
import '../../Skill.css';
import User from '../../User';
import Header from '../../components/Header';
import SkillBackground from '../../SkillBackground.js';
import categories from '../../skill-categories.json';
import skills from '../../skills.json';
import families from '../../skill-families.json';
import convertArrayToObject from '../../utilities';
import CustomXGridContainer from '../../components/CustomXGridContainer';
import CustomXGridTheme from '../../components/CustomXGridContainer/Theme';
import star from '../../img/star.png';
import starred from '../../img/starred.png';
import Proficiency from '../../Proficiency';

class Intelligence extends CustomXGridContainer {

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      filterModel: []
    };
    // this.isSkillUp = window.location.hostname.includes('skillup');
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);
    new User().load().then(user => {
      this.setState({user: user});
    });
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  skillColumnWidth() {
   	return this.state.width < 850 ? 150 : 250;
  }

  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  getData(intelligenceName) {
    return {
      columns: [
        {field: 'id', hide: true},
        {field: 'favorite', headerName: '⭐', width: 50, type:'number', renderCell: this.renderSkillFavorite, disableSelectionOnClick: true},
        {field: 'proficiency', headerName: 'Proficiency', type:"number", width: 65, renderCell: this.renderSkillProficiency, disableSelectionOnClick: true},
        {field: 'skill', headerName: 'Skill', width: this.skillColumnWidth(), renderCell: this.renderGridSkill, disableSelectionOnClick: true},
        {field: 'purpose', headerName: 'Purpose', width: 105, renderCell: (params) => this.renderPurpose(params, this.handlePurposeDotClick, this)},
        {field: 'categories', headerName: 'Intelligences', width: 135, renderCell: (params) => this.renderSkillCategories(params, this.handleCategoryDotClick, this)},
        {field: 'resources', headerName: '# Resources', type: 'number', width: 135},
        {field: 'benefits', headerName: '# Benefits', type: 'number', width: 120},
        {field: 'families', headerName: 'Tags', width: 200},
        {field: 'jobs', headerName: "Related Jobs", width: 3000, renderCell: this.renderJobs}
      ],
      rows: this.getRows(intelligenceName)
    }
  }

  getRows(intelligenceName) {
    const acceptedCategories = Object.values(categories).filter(c => c.isActive).map(c => c.name);
    const skillsSet = convertArrayToObject(skills, '_id');
    const filtered = Object.values(skillsSet).filter(
      s => s.hasOwnProperty('categories') && s.categories.includes(intelligenceName)
    ).sort();

    return filtered.filter(s => s.name !== '').map(skill => {
      return {
        id: skill._id,
        favorite: this.state.user ? (this.state.user.data.favorites.items.findIndex(i => i.skillId === skill._id) > -1 ? 1 : 0) : 0,
        proficiency: this.state.user ? this.getMastery(skill) : 0,
        skill: skill.name,
        purpose: skill.hasOwnProperty('purpose') ? skill.purpose : [],
        categories: skill.hasOwnProperty('categories') ? skill.categories.filter(c => acceptedCategories.includes(c)) : [],
        resources: skill.resources.length,
        benefits: this.getNumBenefits(skill),
        families: skill.hasOwnProperty('families') ? skill.families.map(f => this.titleCase(f)).join(', ') : '',
        jobs: skill.hasOwnProperty('jobs') ? skill.jobs : []
      };
    });
  }

  getMastery(skill) {
    const mastery = this.state.user.data.mastery.items.find(i => i.skillId === skill._id);

    if (mastery) return mastery.proficiency;

    return 0;
  }

  getNumBenefits(skill) {
    let numBenefits = 0;
    
    if (skill.hasOwnProperty('benefits')) numBenefits += skill.benefits.length;
    
    if (skill.hasOwnProperty('families')) {
      skill.families.forEach(f => numBenefits += families[f].benefits.length);
    }
    
    return numBenefits;
  }  

  cellClicked = (param) => {
    const functions = {
      'favorite': this.updateFavorite,
      'proficiency': this.updateProficiency
    };
    
    if (!functions.hasOwnProperty(param.field)) return;
    functions[param.field](this, param);
  }

  updateFavorite(caller, param) {
    if (!caller.state.user) return;

    const skillId = param.row.id;
    const user = caller.state.user;

    const index = user.data.favorites.items.findIndex(i => i.skillId === skillId);
    if (index !== -1) {
      user.deleteFavorite(user.data.favorites.items[index].id, index).then(() => {
        caller.setState({user: user});
      });
    } else {
      user.addFavorite(skillId).then(() => {
        caller.setState({user: user});
      });
    }
  }

  updateProficiency(caller, param) {
    if (!caller.state.user) return;

    const skillId = param.row.id;
    const user = caller.state.user;

    let level = param.row.proficiency;
    level++;
    if (level > 4) level = 0;

    user.updateMastery(skillId, level).then(() => {
      caller.setState({user: user});
    });
  }

  render() {

    var intelligenceName = this.props.match.params.intelligence;
    intelligenceName = decodeURIComponent(intelligenceName);
    const intelligence = categories[intelligenceName];

    if(!intelligence) {
      return (
        <div>
          <Header />
          <div className="content-wrapper">
            <h2>{this.titleCase(intelligenceName.replaceAll('-', ' '))} isn't yet supported.</h2>
          </div>
        </div>
      )
    }
    
    const data = this.getData(intelligenceName);

    return (
      <div>
        <Header color='white' />
        <div className="content-wrapper">
          <SkillBackground skill={intelligence} label={intelligenceName} user={this.state.user} />
        </div>
        <section className="page-section">
          <div style={{width: '100%'}}>
            {this.renderGrid(data, this.cellClicked, this.state.filterModel)}      
          </div>
            
        </section>
      </div>
    );
  }

  renderSkillFavorite(params) {
    const img = params.value === 1 ? starred : star;

    return <img alt={img} src={img} />
  }

  renderSkillProficiency(params) {
    return <Proficiency level={params.value} />
  }
  
  renderGridSkill(params) {
    return <a href={`/skill/${params.value}`}>{params.value}</a>
  }
  
  handleCategoryDotClick(e, params, caller) {
    e.target.setAttribute('data-filter-click', true);

    const spanCategory = params.value[e.target.getAttribute('data-index')];
    
    // If this filter of category already exists, then do nothing
    if (caller.state.filterModel.find(filter =>
      filter.columnField === 'categories' && filter.operatorValue === 'contains' && filter.value === spanCategory)) {
        return;
      }
    
      caller.setState(({filterModel}) => ({
        filterModel: filterModel.concat([{
          columnField: 'categories',
          operatorValue: 'contains',
          value: spanCategory
        }])
      })
    )
  }

  handlePurposeDotClick(e, params, caller) {
    e.target.setAttribute('data-filter-click', true);

    const spanPurpose = params.value[e.target.getAttribute('data-index')];
    
    // If this filter of purpose already exists, then do nothing
    if (caller.state.filterModel.find(filter =>
      filter.columnField === 'purpose' && filter.operatorValue === 'contains' && filter.value === spanPurpose)) {
        return;
      }

      caller.setState(({filterModel}) => ({
        filterModel: filterModel.concat([{
          columnField: 'purpose',
          operatorValue: 'contains',
          value: spanPurpose
        }])
      })
    )
  }
  
  renderSkillCategories(params, handleCategoryDotClick, caller) {
    return params.value.map((c, index) =>
      <img 
        key={c}
        data-index={index}
        src={`${process.env['PUBLIC_URL']}/${c.replace('/', '-')}@72x.png`}
        alt={c}
        width='24'
        />
    );
  }
  
  renderPurpose(params, handlePurposeDotClick, caller) {
    const map = {
      'professional': "👔",
      'personal': "🧑"
    };
  
    return params.value.map((p, index) => 
      <span
        key={p}
        data-index={index}
        className="pointer"
        onClick={(e) => handlePurposeDotClick(e, params, caller)}
      >
        {map[p]}
      </span>
    );
  }

  renderJobs(params) {
    return params.value.sort().map(jobName => 
      <span><a href={`/job/${jobName}`}>{jobName}</a>&nbsp; &nbsp;</span>
    )
  }


}

export default CustomXGridTheme(Intelligence);