import React from 'react';

import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div style={{float: 'left'}}>
          <p>© <a href="http://forestco.co" target="_blank" rel="noopener">Forest Co</a></p>
        </div>
        <div style={{float: 'right'}}>
          <a href="/faq">FAQ</a><br />
          <a href="/contribute">Contribute</a><br /><br />
          <a href="/terms-and-conditions">Terms and Conditions</a><br />
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
    )
  }
}

export default Footer;