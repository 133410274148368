import React from 'react';

import './SkillBackground.css';

class SkillBackground extends React.Component {

  getLabel() {
    if (this.props.label) {
      return this.props.label;
    }

    return `Learn: ${this.props.skill.name}`;
  }

  getPhotoCredit() {
    return this.props.skill.hasOwnProperty('backgroundSource') && this.props.skill.backgroundSource ? 
      this.props.skill.backgroundSource : this.props.skill.background;
  }

  render() {
    return (
      <section className="skill-background">
        {
          this.props.skill && this.props.skill.background?(
            <img alt={this.props.skill.name || ""} src={this.props.skill.background} />
          ):null
        }        
        <div className="skill-header">
          <h1 id="top" style={{fontSize: 'clamp(1.5rem, 2.5vw, 2rem)'}}>{this.getLabel()}</h1>
          {/* <Proficiency user={this.props.user} skillId={this.props.skill._id} noTooltip={true} /> */}
          {
            this.props.skill && this.props.skill.description &&
              <p style={{fontSize: 'clamp(0.9rem, 1.5vw, 1.0rem)'}}>
                {this.props.skill.description} &nbsp;
                {this.props.skill.descriptionSource && <a href={this.props.skill.descriptionSource}>Source</a>}
              </p>
          }
          {
            this.props.skill &&
              <div>
                <a style={{fontSize: 'clamp(0.9rem, 1.5vw, 1.0rem)'}} href={this.getPhotoCredit()}>Photo Credit</a>
              </div>
          }
        </div>
      </section>
    )
  }
}

export default SkillBackground;