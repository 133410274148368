import React from 'react';
import Header from '../../components/Header';
import skills from '../../skills.json';

class Contribute extends React.Component {
  constructor(props) {
    super(props);

    this.siteTitle = window.location.hostname.includes('skillup') ? 'SkillUp Academy' : 'Skills DB';
  }

  render() {
    return (
      <div>
        <Header />
        <div id="top" className="content-wrapper">
          <h1>Contribute to {this.siteTitle}</h1>
          <p>We have the very ambitious mission of being the ultimate website for learning any skill you want to learn. To make that possible, it requires a great deal of research into what it takes to learn each specific skill. We are actively scouring the internet for the best possible resources and building skill trees, mind maps, checklists, and more to make this possible. </p>

          <p>But we’re a very small team and the site isn’t yet profitable, so we need all the help we can get. We’re looking for researchers, writers, skill experts, trainers, and good resources for our library of {skills.length} skills and counting.</p>

          <p><strong>For any type of contribution, please use our chat box in the bottom-right corner and we’ll follow up as quickly as we can.</strong></p>

          <h2>Contribute resources</h2>
          <p>Do you know of a great resource that helped you learn a skill? Let us know, we’d like to include it! If you can send us why you think it’s worth adding, that’s even better.</p>

          <p>If you’ve built skill trees and mind maps, we’d like to include them too, provided they follow our style. </p>

          <h2>Contribute your expertise</h2>
          <p>We’re always looking for trainers (coaches) to link to, so if you’re a coach for a specific skill, let us know and we can include you.</p>

          <p>If you’re an expert on a specific skill, we’d like to pick your brain about what it takes to go from zero to good, from good to great, and from great to unstoppable. We might be interested in doing a public interview as well if you’re interested.</p>

          <h2>Other contributions</h2>
          <p>If you want to write some content and do some research for us, that would be greatly appreciated. We can’t pay you at this point, but we can credit you accordingly.</p>
        </div>
      </div>
    )
  }
}
 
export default Contribute;

