import React from 'react'

class PreFooter extends React.Component {
    render() {
        return (
            <div className="ml-form-embed"
                style={{paddingTop: '50px'}}
                data-account="1105600:z5g0j4m0d5"
                data-form="4692493:m7a2e5">
            </div>
        )
    }
}

export default PreFooter