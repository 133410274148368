import React from 'react';
import CustomXGridContainer from '../CustomXGridContainer';
import CustomXGridTheme from '../CustomXGridContainer/Theme';
import skills from '../../skills.json';
import SearchBarBasic from '../../SearchBarBasic.js';
import convertArrayToObject from '../../utilities';
import categories from '../../skill-categories.json';
import PieChart from "../PieChart"
const skillsSet = convertArrayToObject(skills, 'name');
const acceptedCategories = Object.values(categories).filter(c => c.isActive).map(c => c.name);

class SkillsNeededTab extends CustomXGridContainer {

  constructor(props) {
    super(props);
    
    this.state = {
      width: window.innerWidth,
      filterModel: [],
      gridData: {
        columns: [],
        rows: []
      }
    };
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);
    
    const data = this.getData();
    this.intelligencePieData = acceptedCategories.map(category => {
      let percentage = ((this.intelligencesOccurrences[category] || 0 ) * 100) / this.totalIntelligenceOccurrence;

      return {
        percentage: Math.round(percentage),
        color: categories[category].color,
        label: category,
        link: `/index?category=${encodeURIComponent(category)}&${"favorites=true"}`
      }
    })

    this.intelligencePieData.sort(function(a, b) {
      return b.percentage - a.percentage
    });

    this.tagOccurrences = Object.entries(this.tagOccurrences)
      .sort(([,a],[,b]) => b-a)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    this.setState({
      gridData: data,
      updateChart: true
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  renderTopSkills = (limit) => {
    if (!this.props.data) return this.props.renderSkeleton();
    const ordered = this.props.orderedSkills();

    return (
      <div className="content-wrapper" style={{display: 'inline-block', verticalAlign: "top"}}>
        <h2>Most in-demand skills</h2>
        <ol>
          {
            ordered && ordered.map((entry, index) => 
              index < limit && <li key={"skills"+index}>
                <a href={`/skill/${entry[0]}`}>{entry[0]}</a>tag {entry[1]}
              </li>
            )
          }
        </ol>
      </div>
    );
  }

  renderSkillCategories(params, handleCategoryDotClick, caller) {    
    return <div className="fullHeight" style={{
      paddingTop: 5
    }}>
      {
        params.value.map((c, index) =>
        <img 
          key={c}
          data-index={index}
          src={`${process.env['PUBLIC_URL']}/${c.replace('/', '-')}@72x.png`}
          alt={c}
          width='24'
          />
        )
      }
    </div>;
  }
  
  handleCategoryDotClick(e, params, caller){
    e.target.setAttribute('data-filter-click', true);

    const spanCategory = params.value[e.target.getAttribute('data-index')];
    
    // If this filter of category already exists, then do nothing
    if (caller.state.filterModel.find(filter =>
      filter.columnField === 'categories' && filter.operatorValue === 'contains' && filter.value === spanCategory)) {
        return;
      }
    
      caller.setState(({filterModel}) => ({
        filterModel: filterModel.concat([
          {
            columnField: 'categories',
            operatorValue: 'contains',
            value: spanCategory
          }
        ])
      })
    )
  }

  getData() {
    return {
      columns: [
        {field: 'id', headerName: 'Id', hide: true},
        {field: 'numOfOccurences', headerName: '# Jobs', width: 120},
        {field: 'skill', headerName: 'Skill', width: 200, renderCell: this.renderGridSkill},
        {field: 'categories', headerName: 'Intelligences', width: 135, renderCell: (params) => this.renderSkillCategories(params, this.handleCategoryDotClick, this)},
        {field: 'families', headerName: 'Tags', width: 175, renderCell: this.renderTags},
        {field: 'skills', headerName: "Related Skills", flex: 1, renderCell: this.renderSkills}
      ],
      rows: this.getRows()
    }
  }

  totalIntelligenceOccurrence = 0;
  totalTagOccurrence = 0;

  getIntelligencesBySkill = (skill) => {
    return skillsSet[skill].hasOwnProperty('categories') ? skillsSet[skill].categories.filter(c => acceptedCategories.includes(c)) : [];
  }

  getTagsBySkill = (skill) => {
    return skillsSet[skill].hasOwnProperty('families') ? skillsSet[skill].families : [];
  }

  getRelatedSkills=(grouped, pskills, skillid)=>{

    var result = [];

    function recursiveFx(grouped, skills, numLayers) {
      if(numLayers === 0) return;
      if (skills.length === 0) return
      
      numLayers--;

      if (typeof skills[0] === 'object') {
        skills.filter(id => id !== skillid).map((skill) => {
          if(skills.length)
            recursiveFx(
              grouped, 
              grouped[skill.id].hasOwnProperty('relatedSkills') ? grouped[skill.id].relatedSkills: [],
              numLayers
            )

          result.push(grouped[skill.id].name);

          return skill.id;
        })
      } else {
        skills.filter(id => id !== skillid).map(id => {
            if(skills.length)
              recursiveFx(
                grouped, 
                grouped[id].hasOwnProperty('relatedSkills') ? grouped[id].relatedSkills: [],
                numLayers
              )
  
            result.push(grouped[id].name);
  
            return id;
          }
        )
      }


    }
    recursiveFx(grouped, pskills, 2)

    return result;
  }

  getRows() {    
    const ordered = this.props.orderedSkills();
    const grouped = convertArrayToObject(skills, '_id');

    return ordered && Object.values(ordered).map((entry, index) => {

      let intelligences = this.getIntelligencesBySkill(entry[0]);
      
      if(!this.intelligencesOccurrences) {
        this.intelligencesOccurrences = {};
      } else {        
        intelligences.map(intelligence => {
          if(!this.intelligencesOccurrences[intelligence]) {
            this.intelligencesOccurrences[intelligence] = parseInt(entry[1])            
          } else {
            this.intelligencesOccurrences[intelligence] += parseInt(entry[1])
          } 
          this.totalIntelligenceOccurrence += parseInt(entry[1]);

          return intelligence;
        })        
      }

      let tags = this.getTagsBySkill(entry[0]);
      const relatedSkills = skillsSet[entry[0]]? this.getRelatedSkills(
        grouped, 
        skillsSet[entry[0]].relatedSkills || [], 
        skillsSet[entry[0]]._id
      ): "";

      if(!this.tagOccurrences) {
        this.tagOccurrences = {};
      } else {        
        tags.map(tag => {
          if(!this.tagOccurrences[tag]) {
            this.tagOccurrences[tag] = parseInt(entry[1])            
          } else {
            this.tagOccurrences[tag] += parseInt(entry[1])
          } 
          this.totalTagOccurrence += parseInt(entry[1]);

          return tag;
        })        
      }

      return {
        id: index,
        numOfOccurences: entry[1],
        skill: entry[0],
        categories: intelligences,
        families: skillsSet[entry[0]].hasOwnProperty('families') ? skillsSet[entry[0]].families.join(', ') : '',
        skills: relatedSkills.join(", ")
      };
    });
  }

  renderTags = (params) => {
    return <div className="fullHeight">
      {
        params.value.split(",").sort().map((tagName, index) => 
          <div style={{
              float: 'left'
            }}
            key={"tagName"+index}
          >
            <a href={`/tag/${encodeURIComponent(tagName.trim())}`}>{this.titleCase(tagName.trim())}</a>&nbsp; &nbsp;
          </div>
        )
      }
    </div>
  }

  renderSkills = (params) => {
    return <div className="fullHeight">
      {
        params.value.split(', ').map((skillName,index) => 
          <div style={{
              float: 'left'
            }}
            key={"skillcontainer"+index}
          >
            <a href={`/skill/${encodeURIComponent(skillName)}`}>{this.titleCase(skillName)}</a>&nbsp; &nbsp;
          </div>
        )
      }
    </div>
  }

  renderGridSkill(params) {
    return <div className="fullHeight">
      <a href={`/skill/${encodeURIComponent(params.value)}`}>{params.value}</a>
    </div>
  }

  cellClicked = ()=> {}

  render() {

    return(
      <div>

        <div 
          className="content-wrapper"
          style={{
            margin: '20px 0 20px 0'
          }}
        >
          <SearchBarBasic 
            openSkillpage={true}
            hideHeader={true}
            source={"businessSkills"}
            getData={this.props.orderedSkills}
          />
        </div>

        <div style={{
          margin: 'auto',
          width: '90%',
          display: 'flex',
          flexWrap: 'wrap',
          paddingBottom: '20px'
        }}>
          <div className="dashboard-card" style={{
            margin: '5 auto',
            flex: 1
          }}>
            <h2 className="dashboard-title">
              Intelligences
            </h2>
            <PieChart 
              data={this.intelligencePieData || []}
              updateChart={this.state.updateChart}
            />
          </div>

          <div className="dashboard-card" style={{
            margin: '5 auto',
            flex: 1
          }}>
            <h2 className="dashboard-title">
              Tags
            </h2>
            {Object.keys(this.tagOccurrences || []).map((tag, index) => {
              let percentage = (this.tagOccurrences[tag]*100)/this.totalTagOccurrence;
              if(percentage<0.5) return null;
              
              return this.renderTagProgress(
                percentage,
                tag
              );
            })}
          </div>
        </div>

        <br /><br />
        <div className="dashboard-card" style={{margin: '0 5px 0 5px' }}>
          <h2 className="dashboard-title">All skills needed</h2><br />
          {
            this.renderGrid(
              this.state.gridData, 
              this.cellClicked, 
              this.state.filterModel, 
              {
                pagination: true,
                pageSize: this.props.apiKey ? 50 : 10,
                // autoPageSize=!this.props.apiKey
                gridToolbar: {
                  GridColumnsToolbarButton: true,
                  GridFilterToolbarButton: {
                    loginRequired: this.props.apiKey
                  },
                  GridDensitySelector: true,                
                  GridToolbarExport: {
                    loginRequired: this.props.apiKey
                  }
                }
              }
            )
          }
        </div>
        
      </div>
    )
  }

  renderTagProgress(percentage, tag, className) {
    return (
      <div key={"bar-" + tag}>
        <a 
          style={{float: 'left', width: '25vw', maxWidth:"200px", minWidth: "175px"}}
          href = {`/tag/${encodeURIComponent(tag.trim())}`}
        >
          {this.titleCase(tag)} 
        </a>
        <progress className={className} value={percentage} max={100} />&nbsp;
        {percentage > 0 &&
          <span>
            {percentage.toFixed(2)}%
          </span>
        }
        <br />
      </div>
    );
  }
}

export default CustomXGridTheme(SkillsNeededTab);
