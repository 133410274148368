import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function BusinessTabs(props) {
  const [filter, setFilter] = React.useState(() => props.source ? props.source : 'skills');

  const handleFilter = (event, newFilter) => {
    if (newFilter == null) return;

    setFilter(newFilter);
    props.onFilterUpdated(newFilter);
  };

  return (
    <div>
      <ToggleButtonGroup value={filter} onChange={handleFilter} aria-label="source filter" exclusive>
        <ToggleButton value='openPositions' aria-label="Positions">
          👩‍💼Positions
        </ToggleButton>
        <ToggleButton value='skillsNeeded' aria-label="Skills">
          📚Skills
        </ToggleButton>
        <ToggleButton value='businessData' aria-label="History">
          📈History
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
