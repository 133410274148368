import React from 'react';
import { Hub } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import './MyProfile.css';
import Header from '../../components/Header';
import Recommendations from '../../Recommendations';
import MySkillDistribution from './MySkillDistribution';
import MyProfilePreferences from './MyProfilePreferences';
import User from '../../User';

class MyProfile extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {};
    Hub.listen('auth', this.listener);
  }
  
  componentDidMount() {
    new User().load().then(user => {
      this.setState({user: user});
    });
  }

  listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        this.props.history.push('/me');
        break;
      case 'signOut':
        window.location.reload();
        break;
      case 'signUp':
        this.state.user.save({username: data.payload.username});
        // this.props.history.push('/me');
        break;
      default:
        break;
    }
  }

  updateUser = (user) => {
    this.setState({user: user});
    this.updateBackend(user);
  }

  updateBackend(user) {
    if (typeof user.save !== "function") return;

    const data = {
      username: user.data.username,
      archetype: user.data.archetype,
      learningMethod: user.data.learningMethod,
      chronotype: user.data.chronotype
    };

    
    user.save(data);
  }

  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          {this.renderSignedIn()}
          {/* {this.state.user && !this.state.user.isNew && this.renderSignedIn()}
          {this.state.user && this.state.user.isNew && this.renderSignedOut()} */}
        </div>
      </div>
    )
  }

  renderSignedIn() {
    return (
      <div>
        {/* <AmplifySignOut /> */}
        {/* <br /><br /> */}
        {/* <button onClick={() => this.props.history.push('/me/reflection')}>My Reflection Journal</button> */}
        {/* <MyProfileNavigation /> */}
        <MySkillDistribution user={this.state.user} /><br />
        <MyProfilePreferences user={this.state.user} updateUser={this.updateUser.bind(this)} /><br />
        <Recommendations user={this.state.user} />
      </div>
    );
  }

  renderSignedOut() {
    return (
      <div>
        <button onClick={() => this.props.history.push('/signin')}>Sign in or Create Account</button>
      </div>
    );
  }
}

export default withRouter(MyProfile);