import React from 'react'
import ContactForm from '../../components/Contact/ContactForm'
import Header from '../../components/Header'

class Contact extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <div className="content-wrapper">
                    <div className="content-wrapper">
                        <div className="dashboard-card">
                            <h2 className="dashboard-title">Contact us</h2>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact