import SkillDbCollections from '../../SkillDbCollections'
const { skills, families, skillsSet } = SkillDbCollections;

class TagSearch {

  constructor() {
    this.loadData();
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  loadData = ( ) => {    
    this.tags = Object.keys(families).sort().map((family, index) => {
      const filtered = skills.filter(s => s.hasOwnProperty('families') && s.families.includes(family)).sort();
      
      return {
        id: index,
        name: this.titleCase(family),
        rawName: family,
        skills: filtered.map(s => this.titleCase(skillsSet[s._id].name))
      };
    });
  }

  getResult = (searchTerm) => {
    let data = this.tags && this.tags.filter(
      tag => tag.name.toLowerCase().includes( searchTerm.toLowerCase() )
    );

    return data;
  }

  searchAnchorrefs = []

  goToTag = (tag) => {
    window.location.href = "/tag/" + encodeURIComponent(tag.rawName);
  }

  getModal = (props) => {
 
    let {selectionIndex,index, s} = props

    return ( 
      <li                           
        className="pointer"
        style={{
          backgroundColor: index === selectionIndex ? "#EBEDEF": "white"
        }} 
        onClick={ this.goToTag && this.goToTag.bind(this, s)}
        key={s._id}
      >        
        <a className="orangeColor"
          ref={ x => this.searchAnchorrefs[index] = x }  
          href={'/tag/' + encodeURIComponent(s.rawName)}
        >
          {s.name}
        </a>      
        <div className="relatedJobs">
          {
            s.skills && s.skills.length ? (
              <><span>Related Skills: </span> 
              {
                s.skills && 
                s.skills.slice(0, 20)
                  .map((name,skillId) => name || null)
                  .join(", ")
              }</>
            ) : null
          }
        </div>  
      </li> 
    );
  }

}

export default TagSearch;