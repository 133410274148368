import React from 'react';
import HeaderNavigationMenu from './HeaderNavigationMenu';

class HeaderNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  render() {
    if (this.state.width < 850) {
      return <HeaderNavigationMenu color={this.props.color} />;
    } else {
      return (
        <div className="header-nav">
          <nav className="header-nav-list">
            <div className="header-nav-item">
              <a href="/explore?source=skills" style={{color: this.props.color}}>Skills</a>
            </div>
            <div className="header-nav-item">
              <a href="/explore?source=jobs" style={{color: this.props.color}}>Jobs</a>
            </div>
            <div className="header-nav-item">
              <a href="/courses" style={{color: this.props.color}}>Courses</a>
            </div>
            <div className="header-nav-item">
              <a href="/blog" style={{color: this.props.color}}>Blog</a>
            </div>
            <div className="header-nav-item">
              <a href="/contact" style={{color: this.props.color}}>Contact</a>
            </div>
            <div className="header-nav-item">
              <a href="/me" style={{color: this.props.color}}>Me</a>
            </div>
          </nav>
        </div>
      )
    }
  }
}

export default HeaderNavigation;