import React from 'react';
import './SkillIndex.css';
import Header from './components/Header';
import JobsGrid from './JobsGrid';

class JobIndex extends React.Component {
  
  render() {
    return (
      <div>
        <Header />
        <JobsGrid />
      </div>
    );
  }
}

export default JobIndex;