class BusinessJobSearch {

  constructor(props) {
    this.getData = props.getData;
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  getResult = (searchTerm) => {
    if(!this.jobs) {
      this.jobs = this.getData();
      this.jobs = this.jobs.sort((a, b) => a.name.localeCompare(b.name));
    }
    
    let data = this.jobs && this.jobs.filter(
      job => job.name.toLowerCase().includes( searchTerm.toLowerCase() )
    );

    return data;
  }

  searchAnchorrefs = []

  goToJob = (job) => {
    window.location.href = job.url;
  }

  getModal = (props) => {
 
    let {selectionIndex,index, s} = props

    return (
      <li
        className="pointer"
        style={{
          backgroundColor: index === selectionIndex ? "#EBEDEF": "white"
        }}
        onClick={ this.goToJob && this.goToJob.bind(this, s)}
        key={"modal-job-row-"+index}
      >
        <a className="orangeColor"
          ref={ x => this.searchAnchorrefs[index] = x }
          href={ s.url}
        >
          {s.name}, {s.location}
        </a>
      </li> 
    );
  }

}

export default BusinessJobSearch;