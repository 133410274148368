import React from 'react';

import './SkillIndex.css';

import categories from './skill-categories.json';
import Header from './components/Header';
import SkillsGrid from './SkillsGrid.js';
import SearchBarBasic from './SearchBarBasic.js';


class SkillIndex extends React.Component {
  
  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <SearchBarBasic openSkillpage={true} hideHeader={true} />
        </div>
        <br /><br />
        <SkillsGrid />
        <div className="content-wrapper">
          <h4>Legend</h4>
          <ul style={{listStyle: 'none'}}>
            <li>👔 = Professional</li>
            <li>🧑 = Personal</li>
            {
              Object.values(categories).filter(c => c.isActive).map(c => 
                <li key={c.name}>
                  <span className="label dot" style={{backgroundColor: c.color}}>&nbsp;</span>
                  = {c.name}
                </li>
              )
            }
          </ul>
        </div>
      </div>
    );
  }
}

export default SkillIndex;