import * as React from 'react';
import skills from './skills.json';
import categories from './skill-categories.json';
import User from './User';
import CustomXGridContainer from './components/CustomXGridContainer';
import CustomXGridTheme from './components/CustomXGridContainer/Theme';
import convertArrayToObject from './utilities';

class CategoriesGrid extends CustomXGridContainer {
  constructor(props) {
    super(props);
    
    this.state = {
      width: window.innerWidth,
      filterModel: [],
      skills: convertArrayToObject(skills, '_id'),
      gridData: {
        columns:[],
        rows: []
      }
    };
  }  
  
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);

    new User().load().then(user => {
      this.setState({user: user});
    });

    const data = this.getData();
    this.setState({
      gridData: data
    })        
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  
  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }
  
  getData() {
    return {
      columns: [
        {field: 'id', headerName: 'Id', hide: true},
        {field: 'intelligence', headerName: 'Intelligence', width: 220, renderCell: this.renderIntelligence},
        {field: 'skills', headerName: "Related Skills", flex: 1, renderCell: this.renderSkills}
      ],
      rows: this.getRows()
    }
  }
  
  getRows() {
    return Object.values(categories).filter(c => c.isActive).map((category, index) => {
      const filtered = Object.values(this.state.skills).filter(s => s.public && s.hasOwnProperty('categories') && s.categories.includes(category.name)).sort();

      return {
        id: index,
        intelligence: category,
        skills: filtered.map(s => this.state.skills[s._id].name)
      };
    });
  }

  cellClicked = ()=> {}
  
  //
  // RENDER
  render() {
    return (
      <div style={{width: '100%' }}>
        {this.renderGrid(this.state.gridData, this.cellClicked, this.state.filterModel)}
      </div>
    );
  }

  renderIntelligence(params) {
    return (
      <div className="fullHeight" style={{display: 'inline-block'}}>
        <img 
          key={params.value.name}
          src={`${process.env['PUBLIC_URL']}/${params.value.name.replace('/', '-')}@72x.png`}
          alt={params.value.name}
          width='24'
          />
        <a href={`/intelligence/${encodeURIComponent(params.value.name)}`}> {params.value.name}</a>
      </div>
    ) 
            
  }

  renderSkills = (params) => {
    return <div className="fullHeight">
      {
        params.value.map((skillName, index) => 
          <div style={{
              float: 'left'
            }}
            key={"skillcontainer"+index}
          >
            <a href={`/skill/${skillName}`}>{this.titleCase(skillName)}</a>&nbsp; &nbsp;
          </div>
        )
      }
    </div>
  }
  
}

export default CustomXGridTheme(CategoriesGrid);