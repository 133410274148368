import convertArrayToObject from '../../utilities';
import SkillDbCollections from '../../SkillDbCollections'
const { skills, categories } = SkillDbCollections;

class SkillSearch {

  constructor() {
    this.loadData();
  }

  loadData = () => {
    this.fetchPublicSkills();
    this.groupedSkills = convertArrayToObject(this.publicSkills, '_id');
  }

  fetchPublicSkills () {
    const sortedSkills = skills.sort((a, b) => a.name.localeCompare(b.name));
    this.publicSkills = sortedSkills.filter( s => s.public );
  }

  getResult = (searchTerm) => {
    let data = this.publicSkills && this.publicSkills.filter(
      s => s.name.toLowerCase().includes(searchTerm.toLowerCase()) || (s.hasOwnProperty('alternateNames') && s.alternateNames.map(an => an.toLowerCase()).includes(searchTerm.toLowerCase()))
    );

    return data;
  }

  goToSkill = (skill) => {
    window.location.href = '/skill/'+encodeURIComponent(skill.name.toLowerCase().replaceAll('-', '').replaceAll(' ', '-'));
  }

  searchAnchorrefs = []

  renderSkillCategories(skill) {
    if (!skill.categories) return "";

    return skill.categories.map((c, index) =>
      categories.hasOwnProperty(c) && categories[c].isActive &&
        <img 
        key={c}
        data-index={index}
        src={`${process.env['PUBLIC_URL']}/${c.replace('/', '-')}@72x.png`}
        alt={c}
        width='24'
        />
    )
  }

  getModal = (props) => {
 
    let {selectionIndex,index, s} = props

    return ( 
      <li                           
        className="pointer"
        style={{
          backgroundColor: index === selectionIndex ? "#EBEDEF": "white"
        }} 
        onClick={ this.goToSkill && this.goToSkill.bind(this, s)}
        key={s._id}
      >
        {/* <Procificency user={this.state.user} skillId={s._id} />&nbsp; Removing for basic version */}
        <a className="orangeColor"
          ref={ x => this.searchAnchorrefs[index] = x }  
          href={'/skill/' + encodeURIComponent(s.name.toLowerCase().replaceAll('-', '').replaceAll(' ', '-'))}
        >
          {s.name}
        </a>
        <div className="relatedJobs">
          {
            s.relatedSkills && this.groupedSkills ? (
              <><span>Related Skills: </span> 
              {
                s.relatedSkills
                  .map((skill) => {
                    if (typeof skill === 'object') {
                      return (this.groupedSkills.hasOwnProperty(skill.id) ? this.groupedSkills[skill.id].name : null)
                    } else {
                      return (this.groupedSkills.hasOwnProperty(skill) ? this.groupedSkills[skill].name : null)
                    }
                  })
                  .filter((skillName) => skillName !== null)
                  .join(", ")
              }</>
            ) : null
          }
        </div>
        <div>
          {
            this.renderSkillCategories(s)
          }
        </div>
      </li> 
    );
  }

}

export default SkillSearch;