/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      username
      archetype
      learningMethod
      chronotype
      createdAt
      updatedAt
      mastery {
        items {
          id
          userID
          skillId
          proficiency
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      favorites {
        items {
          id
          userID
          skillId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      username
      archetype
      learningMethod
      chronotype
      createdAt
      updatedAt
      mastery {
        items {
          id
          userID
          skillId
          proficiency
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      favorites {
        items {
          id
          userID
          skillId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      username
      archetype
      learningMethod
      chronotype
      createdAt
      updatedAt
      mastery {
        items {
          id
          userID
          skillId
          proficiency
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      favorites {
        items {
          id
          userID
          skillId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createUserFavorite = /* GraphQL */ `
  mutation CreateUserFavorite(
    $input: CreateUserFavoriteInput!
    $condition: ModelUserFavoriteConditionInput
  ) {
    createUserFavorite(input: $input, condition: $condition) {
      id
      userID
      skillId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserFavorite = /* GraphQL */ `
  mutation UpdateUserFavorite(
    $input: UpdateUserFavoriteInput!
    $condition: ModelUserFavoriteConditionInput
  ) {
    updateUserFavorite(input: $input, condition: $condition) {
      id
      userID
      skillId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserFavorite = /* GraphQL */ `
  mutation DeleteUserFavorite(
    $input: DeleteUserFavoriteInput!
    $condition: ModelUserFavoriteConditionInput
  ) {
    deleteUserFavorite(input: $input, condition: $condition) {
      id
      userID
      skillId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserMastery = /* GraphQL */ `
  mutation CreateUserMastery(
    $input: CreateUserMasteryInput!
    $condition: ModelUserMasteryConditionInput
  ) {
    createUserMastery(input: $input, condition: $condition) {
      id
      userID
      skillId
      proficiency
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserMastery = /* GraphQL */ `
  mutation UpdateUserMastery(
    $input: UpdateUserMasteryInput!
    $condition: ModelUserMasteryConditionInput
  ) {
    updateUserMastery(input: $input, condition: $condition) {
      id
      userID
      skillId
      proficiency
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserMastery = /* GraphQL */ `
  mutation DeleteUserMastery(
    $input: DeleteUserMasteryInput!
    $condition: ModelUserMasteryConditionInput
  ) {
    deleteUserMastery(input: $input, condition: $condition) {
      id
      userID
      skillId
      proficiency
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createJobs = /* GraphQL */ `
  mutation CreateJobs(
    $input: CreateJobsInput!
    $condition: ModelJobsConditionInput
  ) {
    createJobs(input: $input, condition: $condition) {
      id
      business
      url
      date
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateJobs = /* GraphQL */ `
  mutation UpdateJobs(
    $input: UpdateJobsInput!
    $condition: ModelJobsConditionInput
  ) {
    updateJobs(input: $input, condition: $condition) {
      id
      business
      url
      date
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteJobs = /* GraphQL */ `
  mutation DeleteJobs(
    $input: DeleteJobsInput!
    $condition: ModelJobsConditionInput
  ) {
    deleteJobs(input: $input, condition: $condition) {
      id
      business
      url
      date
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
