import React from 'react';

import closed from '../../img/Hamburger_icon.svg.png';
import closedWhite from '../../img/white-Hamburger_icon.svg.png';
import opened from '../../img/x.png';

class HeaderNavigationMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  handleClick() {
    this.setState ({isOpen: !this.state.isOpen});
  }

  closedIcon() {
    if (this.props.color !== "white") {
      return closed;
    } else {
      return closedWhite;
    }
  }

  render() {
    return (
      <div>
        <div className="top-right">
          <img src={this.closedIcon()} alt="Close" width="33px" className="pointer" onClick={this.handleClick.bind(this)} />
        </div>
        <div className="menu-modal" style={{display: this.state.isOpen ? 'block' : 'none'}}>
          <div className="top-right">
            <img src={opened} alt="Opened" width="33px" className="pointer" onClick={this.handleClick.bind(this)} />
          </div>
          <div className="v-padding30">
            <center className="v-padding30">
              <ul style={{padding: '0'}}>
                  <li className="nav-item">
                    <a href="/explore?source=skills">Skills</a>
                  </li>
                  <li className="nav-item">
                    <a href="/explore?source=jobs">Jobs</a>
                  </li>
                  <li className="nav-item">
                    <a href="/me">Me</a>
                  </li>
                  <li className="nav-item">
                    <a href="/courses">Courses</a>
                  </li>
                  <li className="nav-item">
                    <a href="/blog">Blog</a>
                  </li>
                  <li className="nav-item">
                    <a href="/contact">Contact</a>
                  </li>
              </ul>
            </center>
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderNavigationMenu;