import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import proficiency0 from './img/proficiency/0.png';
import proficiency1 from './img/proficiency/1.png';
import proficiency2 from './img/proficiency/2.png';
import proficiency3 from './img/proficiency/3.png';
import proficiency4 from './img/proficiency/4.png';

export default function ProficiencyFilter(props) {
  const [filters, setFilters] = React.useState(() => props.proficiencies ? props.proficiencies : [0, 1, 2, 3, 4]);

  const handleFilters = (event, newFilters) => {
    setFilters(newFilters);
    props.onFiltersUpdated(newFilters);
  };

  return (
    <table>
      <tbody>
        <tr>
          <td>Proficiencies: </td>
          <td>
            <ToggleButtonGroup value={filters} onChange={handleFilters} aria-label="proficiency filter">
              <ToggleButton value={0} aria-label="0">
                <img alt="zero" src={proficiency0} width="24" />&nbsp;
              </ToggleButton>
              <ToggleButton value={1} aria-label="1">
                <img alt="beginner" src={proficiency1} width="24" />&nbsp;
              </ToggleButton>
              <ToggleButton value={2} aria-label="2">
                <img alt="intermediate" src={proficiency2} width="24" />&nbsp;
              </ToggleButton>
              <ToggleButton value={3} aria-label="3">
                <img alt="expert" src={proficiency3} width="24" />&nbsp;
              </ToggleButton>
              <ToggleButton value={4} aria-label="4">
                <img alt="master" src={proficiency4} width="24" />&nbsp;
              </ToggleButton>
            </ToggleButtonGroup>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
