import { Auth, API, graphqlOperation } from 'aws-amplify';
import { userByUsername } from './graphql/queries';
import { 
  createUserData, 
  updateUserData, 
  createUserFavorite,
  deleteUserFavorite,
  createUserMastery,
  updateUserMastery
} from './graphql/mutations';

export default class User {
  constructor() {
    this.data = JSON.parse(window.localStorage.getItem('user') || '{}');
    this.isNew = true;

    // Migrate favorites to new format
    if (this.data.favorites && Array.isArray(this.data.favorites)) {
      const favorites = [...this.data.favorites];
      this.data.favorites = {items: []}
      favorites.forEach(f => {
        this.data.favorites.items.push({id: f, skillId: f});
      });
    }

    // Migrate mastery to new format
    if (this.data.mastery && !this.data.mastery.hasOwnProperty('items')) {
      const mastery = Object.keys(this.data.mastery).map(key => {
        return {id: key, skillId: key, proficiency: this.data.mastery[key]}
      });
      this.data.mastery = {items: mastery};
    }

    // Assign default values if user hasn't yet added favorites or mastery
    if (!this.data.favorites) this.data.favorites = {items: []};
    if (!this.data.mastery  ) this.data.mastery   = {items: []};

  }
  
  async load() { 
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.data.username = user.username;
    }
    catch {
      return this; // User is not logged in
    }

    const response = await API.graphql(graphqlOperation(userByUsername, {username: this.data.username}));
    const results = response.data.userByUsername.items;
    if (results.length > 0) {
      // TODO: Sync with localStorage
      this.data = results[0];
      this.isNew = false;

      return this;
    } else {
      return this;
    }
  }

  async save(data) {
    try {
      // Logged in
      await Auth.currentAuthenticatedUser();

      if (!this.isNew) {
        data.id = this.data.id;
        await API.graphql(graphqlOperation(updateUserData, {input: data}));
      } else {
        await API.graphql(graphqlOperation(createUserData, {input: data}));
        this.isNew = false;
      }
    } catch {
      // Not logged in
    }

    Object.keys(data).forEach(key => this.data[key] = data[key]);

    this.saveLocal();
  }

  async addFavorite(skillId) {
    const data = {
      userID: this.data.id,
      skillId: skillId
    };

    let added = null;

    try { 
      // Logged in
      await Auth.currentAuthenticatedUser();
      const response = await API.graphql(graphqlOperation(createUserFavorite, {input: data}));
      this.data.favorites.items.push(response.data.createUserFavorite);
      added = response.data.createUserFavorite;
    } catch {
      // Not logged in
      added = {
        id: skillId,
        skillId: skillId,
      };
      this.data.favorites.items.push(added);
    }
    this.saveLocal();

    return added;
  }

  async deleteFavorite(id, index) {
    try {
      // logged in
      await Auth.currentAuthenticatedUser();
      await API.graphql(graphqlOperation(deleteUserFavorite, {input: {id: id}}));
    } catch {
      // not logged in
    }

    this.data.favorites.items.splice(index, 1);
    this.saveLocal();

    return {};
  }

  async updateMastery(skillId, proficiency) {
    const data = {
      userID: this.data.id,
      skillId: skillId,
      proficiency: proficiency
    };

    const obj = this.data.mastery.items.find(i => i.skillId === skillId);
    if (obj) {
      data.id = obj.id;
      obj.proficiency = data.proficiency;

      try {
        // Logged in
        await Auth.currentAuthenticatedUser();
        await API.graphql(graphqlOperation(updateUserMastery, {input: data}));
      } catch {
        // Not logged in
      }

      this.saveLocal();

      return;
    } else {
      let data = null;
      try {
        // Logged in
        await Auth.currentAuthenticatedUser();
        const response = await API.graphql(graphqlOperation(createUserMastery, {input: data}));
        data = response.data.createUserMastery;
        
      } catch {
        // Not logged in
        data = {
          id: skillId,
          skillId: skillId,
          proficiency: proficiency
        };
      }
      this.data.mastery.items.push(data);
      this.saveLocal();
      
      return data;
    }
  }
  
  saveLocal() {
    window.localStorage.setItem('user', JSON.stringify(this.data));
  }
}