import React from 'react'
import starImg from '../../img/star.png';
import starredImg from '../../img/starred.png';

class AddToFavorite extends React.Component {

  toggle = (e)=> {
    e.preventDefault();
    this.props.updateFavorite();
  }

  render() {
    if(this.props.favorite) {
      return(
        <div style={{
          textAlign: 'center',
          width: 250,				
        }}>
        	<div onClick={this.toggle} className="rmfavbtn rmfavbtn1">
            <div style={{
              display: 'flex',
              height: 24,
            }}>
              <img alt="" style={{marginRight: "10px"}} width="24" height="24" src={starredImg} />
              {/* <span style={{flex:1, textAlign:'left' , margin:'auto'}}>Remove Favorite</span> */}
            </div>
          </div>
        </div>
      )
    }

    return(
      <div style={{
        textAlign: 'center',
        width: 200,				
      }}>
        <div onClick={this.toggle} className="favbtn favbtn1">
          <div style={{
            display: 'flex',
            height: 24,
          }}>
            <img alt="" style={{marginRight: "10px"}} width="24" height="24" src={starImg} />
            {/* <span style={{flex:1, textAlign:'left' , margin:'auto'}}>Add Favorite</span> */}
         </div>
        </div>
      </div>
    )
  }
}

export default AddToFavorite;
