import SkillDbCollections from '../../SkillDbCollections'
const { blogs } = SkillDbCollections;

class BlogSearch {

  constructor() {
    this.loadData();
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  loadData = ( ) => { 
    this.blogs = Object.keys(blogs).sort().map((blogId, index) => {
      let blog = blogs[blogId]
      return {
        id: index,
        name: this.titleCase(blog.title),
        rawName: blog.title,
        summary: blog.summary,
        url: blog.url
      };
    });
  }

  getResult = (searchTerm) => {
    let data = this.blogs && this.blogs.filter(
      blog => blog.name.toLowerCase().includes( searchTerm.toLowerCase() )
    );

    return data;
  }

  searchAnchorrefs = []

  goToBlog = (blog) => {
    window.location.href = "/blog/" + blog.url;
  }

  getModal = (props) => {
 
    let {selectionIndex,index, s} = props

    return ( 
      <li                           
        className="pointer"
        style={{
          backgroundColor: index === selectionIndex ? "#EBEDEF": "white"
        }} 
        onClick={ this.goToBlog && this.goToBlog.bind(this, s)}
        key={s._id}
      >        
        <a className="orangeColor"
          ref={ x => this.searchAnchorrefs[index] = x }  
          href={'/blog/' + s.url}
        >
          {s.name}
        </a>
        <div>
          {s.summary}
        </div>
      </li> 
    );
  }

}

export default BlogSearch;