import React from 'react';

import './Blog.css';

import Header from '../../components/Header';
import SearchBar from '../../SearchBar.js';
import blog from '../../blog.json';

class Blog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      html: ""
    };
  }

  componentDidMount() {
    this.loadFile(blog[this.props.match.params.blog].file);

    document.title = blog[this.props.match.params.blog].title;
  }

  loadFile(url) {
    console.log(url);
    fetch(url)
      .then(res => res.text())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            html: result
          });
      }, err => {
        //handle your error here
      }
    );
  }

  render() {
    const entry = blog[this.props.match.params.blog];

    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="content-wrapper">
            <p>Written by {entry.author}</p>
            <p>Last updated: {entry.updatedAt}</p>
            <h1>{entry.title}</h1>
            <div dangerouslySetInnerHTML={{__html: this.state.html}} />
            <br />
            <hr />
            <SearchBar />
          </div>
        </div>
      </div>
    )
  }
}

export default Blog;