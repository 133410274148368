import React from 'react';

import './SkillCategory.css';

import logical from './img/logical.png';
import musical from './img/musical.png';
import visual from './img/visual.png';
import verbal from './img/verbal.png';
import kinesthetic from './img/kinesthetic.png';
import interpersonal from './img/interpersonal.png';
import intrapersonal from './img/intrapersonal.png';
import naturalistic from './img/naturalistic.png';


class SkillCategory extends React.Component {
  render() {
    const images = {
      'logical': logical,
      'musical': musical,
      'visual': visual,
      'verbal': verbal,
      'kinesthetic': kinesthetic,
      'interpersonal': interpersonal,
      'intrapersonal': intrapersonal,
      'naturalistic': naturalistic
    };

    const category = this.props.category;
    return (
      <a href={"/index?category=" + category.name}>
        <div className="card" style={{backgroundImage: "url("+images[category.image]+")", backgroundSize: 'cover'}}>
          <div style={{padding: '20px'}}>
            <div className="bottom-label" style={{backgroundColor: category.color}}>
              <h3>{this.props.category.name}</h3>
            </div>
          </div>
        </div>
      </a>
    )
  }
}

export default SkillCategory;