import React from 'react';

import Header from './components/Header';
import SearchBar from './SearchBar';
import SkillCategory from './SkillCategory';

import categories from './skill-categories.json';
import skills from './skills.json';

class SkillCategories extends React.Component {

  componentDidMount() {
    document.title = "Choose a skill category to learn | SkillUp Academy";
  }

  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <center>
          <h1>Skill Intelligences</h1>
          {
            Object.keys(categories).map(k =>
              categories[k].isActive &&
                <SkillCategory category={categories[k]} />
            )
          }
          </center>
          <br /><br /><br />
          <div className="content-wrapper">
            <em>Illustrations licensed from <a href="https://vectorstock.com">VectorStock.com</a></em><br /><br />
            <h2>Why these skill Intelligences?</h2>
            <p>The skill Intelligences are based on <a href="https://howardgardner.com/" target="_blank" rel="noopener noreferrer">Howard Gardner</a>'s <a href="https://www.verywellmind.com/gardners-theory-of-multiple-intelligences-2795161#:~:text=In%20order%20to%20capture%20the,%2Dvisual%2C%20and%20linguistic%20intelligences." target="_blank" rel="noopener noreferrer">theory of multiple intelligences</a>. While <a href="https://www.edutopia.org/article/multiple-intelligences-theory-widely-used-yet-misunderstood" target="_blank" rel="noopener noreferrer">his theory hasn't proven to be entirely accurate</a>, it makes for a good starting point for dividing skills into different Intelligences.</p>
            <p>As we went through our current list of available skills, we realized that they all fit into one of the "intelligences". Some of them even belonged in a few "intelligences". As strong believers in having a balanced brain and skills, we decided to make this as clear as possible to you, the learner. That way, you can more easily choose from areas of your brain you want to work on, either to strengthen, or to balance it.</p>
            
            <h2>What to expect from these skill Intelligences?</h2>
            <h3 className="label" style={{backgroundColor: categories['Logical/Analytical'].color}}>Logical/Analytics</h3>
            <p>In the Logical/Analytical category, you'll find skills that relate to sciences, programming, accounting, and any other skill that involves using and manipulating numbers.</p>
            <a href="/index?category=Logical/Analytical">Browse Logical/Analytical skills ({skills.filter(s => s.categories && s.categories.indexOf('Logical/Analytical') !== -1).length})</a>

            <h3 className="label" style={{backgroundColor: categories['Musical-rhythmic'].color}}>Musical-rhythmic</h3>
            <p>In the Musical-rhythmic category, you'll find skills that relate to sounds, music, dancing, and any other skill that involves using your hearing senses.</p>
            <a href="/index?category=Musical-rhythmic">Browse Musical-rhythmic skills ({skills.filter(s => s.categories && s.categories.indexOf('Musical-rhythmic') !== -1).length})</a>

            <h3 className="label" style={{backgroundColor: categories['Visual-spatial'].color}}>Visual-spatial</h3>
            <p>In the Visual-spatial category, you'll find skills that relate to photography, art, architecture, and any other skill that involves using your visual creativity.</p>
            <a href="/index?category=Visual-spatial">Browse Visual-spatial skills ({skills.filter(s => s.categories && s.categories.indexOf('Visual-spatial') !== -1).length})</a>

            <h3 className="label" style={{backgroundColor: categories['Verbal-Linguistic'].color}}>Verbal-linguistic</h3>
            <p>In the Verbal-linguistic category, you'll find skills that relate to languages, communication, writing, and any other skill that involves using words or body language.</p>
            <a href="/index?category=Verbal-Linguistic">Browse Verbal-linguistic skills ({skills.filter(s => s.categories && s.categories.indexOf('Verbal-Linguistic') !== -1).length})</a>

            <h3 className="label" style={{backgroundColor: categories['Bodily-kinesthetic'].color}}>Bodily-kinesthetic</h3>
            <p>In the Bodily-kinesthetic category, you'll find skills that relate to sports, fitness, wellness, and any other skill that involves moving your body through physical activity.</p>
            <a href="/index?category=Bodily-kinesthetic">Browse Bodily-kinesthetic skills ({skills.filter(s => s.categories && s.categories.indexOf('Bodily-kinesthetic') !== -1).length})</a>

            <h3 className="label" style={{backgroundColor: categories['Interpersonal'].color}}>Interpersonal</h3>
            <p>In the Interpersonal category, you'll find skills that relate to management, empapthy, communication, and any other skill that involves interacting with other people.</p>
            <a href="/index?category=Interpersonal">Browse Interpersonal skills ({skills.filter(s => s.categories && s.categories.indexOf('Interpersonal') !== -1).length})</a>

            <h3 className="label" style={{backgroundColor: categories['Intrapersonal'].color}}>Intrapersonal</h3>
            <p>In the Intrapersonal category, you'll find skills that relate to self-awareness, journaling, meditation, and any other skill that involves an introspective look at your self.</p>
            <a href="/index?category=Intrapersonal">Browse Intrapersonal skills ({skills.filter(s => s.categories && s.categories.indexOf('Intrapersonal') !== -1).length})</a>

            <h3 className="label" style={{backgroundColor: categories['Naturalistic'].color}}>Naturalistic</h3>
            <p>In the Naturalistic category, you'll find skills that relate to biology, outdoors, farming, and any other skill that involves nature.</p>
            <a href="/index?category=Naturalistic">Browse Naturalistic skills ({skills.filter(s => s.categories && s.categories.indexOf('Naturalistic') !== -1).length})</a>
            <br /><br />

            <h2>What should I focus on?</h2>
            <p>That really depends on you. You can <a href="/me#recommendations">check out your profile page for some recommendations</a> based on the principles below.</p>
            
            <h3>What skills do I current have?</h3>
            <p>As you browse through the different Intelligences, note what skills you possess and at what proficiency. You'll start noticing where your strengths and weaknesses are.</p>
            
            <h3>What is my learner archetype?</h3>
            <ul>
              <li><strong>Generalist</strong>: Someone who likes to learn many things but doesn't go very deep in most skills.</li>
              <li><strong>Specialist</strong>: Someone who likes to learn things that relate to the same or similar field.</li>
              <li><strong>Polymath</strong>: Someone who likes to learn many things and specialize in a few different fields.</li>
            </ul>
            <p>More on that in the <a href="/faq#archetypes">FAQ</a>.</p>

            <h3>What is my learning preference?</h3>
            <ul>
              <li><strong>Focus on my strengths</strong>: You like to improve upon skills you already have a proficiency in.</li>
              <li><strong>Focus on my weaknesses</strong>: You like to learn entirely new skills.</li>
              <li><strong>Focus on balance</strong>: You like to learn entirely new skills, but also improve upon skills you already have a proficiency in.</li>
            </ul>
            <p>More on that in the <a href="/faq#learningMethods">FAQ</a>.</p>

            <h3>Okay, now what?</h3>
            <p>You can figure out what skills to focus on!</p>
            <ul>
              <li>If you're a generalist, you'll want to learn skills across the 8 different skill Intelligences.</li>
              <li>If you're a specialist, you'll want to focus on one or two of the 8 skill Intelligences.</li>
              <li>If you're a polymath, you'll want to either focus one category to get very good at it or move on to another category since you're already very good in it.</li>
            </ul>
            <p>From there, you'll want to choose skills based on your learning preference. Do you feel like going the hard way and learning something new entirely, or do you want to have it a little easier and improve upon some of the skills and techniques you already know?</p>
          </div>
        </div>
        <br />
        <hr />
        <div className="content-wrapper">
          <SearchBar />
        </div>
      </div>
    )
  }
}

export default SkillCategories;