import React from 'react';
import SkillSearch from './SkillSearch';
import JobSearch from './JobSearch';
import IntelligenceSearch from './IntelligenceSearch';
import TagSearch from './TagSearch';
import BlogSearch from './BlogSearch';
import BusinessJobSearch from './BusinessJobSearch';
import BusinessSkillsSearch from './BusinessSkillsSearch';

class Container extends React.Component {
  
  constructor(props) {
    super(props);
    this.dataSource = {}
  }

  componentDidMount() {
    this.digestSearchData(this.props.source)
  }

  digestSearchData = (source) => {
    switch(source) {
      case "tags":
        this.dataSource["tags"] = new TagSearch();
        break;
      case "intelligences":
        this.dataSource["intelligences"] = new IntelligenceSearch();
        break;
      case "jobs":
        this.dataSource["jobs"] = new JobSearch();
        break;
      case "blogs":
        this.dataSource["blogs"] = new BlogSearch();
        break;
      case "businessJobs":
        this.dataSource["businessJobs"] = new BusinessJobSearch({
          getData: this.props.getData
        });
        break;  
      case "businessSkills":
        this.dataSource["businessSkills"] = new BusinessSkillsSearch({
          getData: this.props.getData
        });
        break;
      default:
        if(!this.dataSource["skills"])
          this.dataSource["skills"] = new SkillSearch();
    }
  }

  getResult = ( searchTerm ) => {
    let data = [];
    let source = this.props.source || "skills";
    if(!this.dataSource[source]) {
      this.digestSearchData(source);
    }
    data = (this.dataSource[source] && this.dataSource[source].getResult(searchTerm)) || [];    
    
    return data;
  }

  renderModal = ( ) => {
    let selectionIndex = this.getSelectionIndex() || 0 ;
    let data = this.getStateData() || [];
    let source = this.props.source || "skills";
    
    return data.map( (s, index) => {      
        return this.dataSource[source].getModal({
          index: index,
          s: s,
          selectionIndex: selectionIndex
        })
    })
  }

  getLinksRef = () => {
    let source = this.props.source || "skills";
    
    return (this.dataSource[source] && this.dataSource[source].searchAnchorrefs) || [];
  }

}

export default Container;