import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DatePickerFn = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker
      popperPlacement={props.placement ? props.placement : "bottom-end"} 
      selected={props.selected ? props.selected : startDate} 
      onChange={(date) => {
        setStartDate(date); 
        props.handleDateChanged(date);
    }} />
  );
};

export default DatePickerFn