import SkillDbCollections from '../../SkillDbCollections'
const { categories, skillsSet } = SkillDbCollections;

class IntelligenceSearch {

  constructor() {
    this.loadData();
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  loadData = ( ) => {

    this.intelligences = Object.values(categories).filter(c => c.isActive).map((category, index) => {
      const filtered = Object.values(skillsSet).filter(s => s.hasOwnProperty('categories') && s.categories.includes(category.name)).sort();
      
      return {
        id: index,
        name: category.name,
        skills: filtered.map(s => this.titleCase(skillsSet[s._id].name))
      };
    });
  }

  getResult = (searchTerm) => {
    let data = this.intelligences && this.intelligences.filter(
      intelligence => intelligence.name.toLowerCase().includes( searchTerm.toLowerCase() )
    );
    
    return data;
  }

  searchAnchorrefs = []

  goToIntelligence = (intelligence) => {
    window.location.href = "/intelligence/" + encodeURIComponent(intelligence.name);
  }

  getModal = (props) => {
 
    let {selectionIndex,index, s} = props

    return ( 
      <li                           
        className="pointer"
        style={{
          backgroundColor: index === selectionIndex ? "#EBEDEF": "white"
        }} 
        onClick={ this.goToIntelligence && this.goToIntelligence.bind(this, s)}
        key={s._id}
      >        
        <a className="orangeColor"
          ref={ x => this.searchAnchorrefs[index] = x }  
          href={'/intelligence/' + encodeURIComponent(s.name)}
        >
          {s.name}
        </a>        
        <div className="relatedJobs">
          {
            s.skills && s.skills.length ? (
              <><span>Related Skills: </span> 
              {
                s.skills && 
                s.skills.slice(0, 20)
                  .map((name,skillId) => name || null)
                  .join(", ")
              }</>
            ) : null
          }
        </div>
      </li> 
    );
  }

}

export default IntelligenceSearch;