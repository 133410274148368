// Code borrowed from: https://stackoverflow.com/questions/53456764/how-to-embed-google-trends-into-react-app

import React from "react";
import Script from "react-load-script";

export default function GoogleTrends({ type, keyword, url }) {
  const handleScriptLoad = () => {
    window.trends.embed.renderExploreWidgetTo(
      document.getElementById("widget"),
      type,
      {
        comparisonItem: [{ keyword, geo: "", time: "today 5-y" }],
        category: 0,
        property: ""
      },
      {
        exploreQuery: `q=${encodeURI(keyword)}&geo=&date=today%205-y`,
        guestPath: "https://trends.google.com:443/trends/embed/"
      }
    );
  };

  const renderGoogleTrend = () => {
    return <Script url={url} onLoad={handleScriptLoad} />;
  };

  return <div className="googleTrend">{renderGoogleTrend()}</div>;
}