import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function ExploreSourceSelector(props) {
  const [filter, setFilter] = React.useState(() => props.source ? props.source : 'skills');

  const handleFilter = (event, newFilter) => {
    if (newFilter == null) return;

    setFilter(newFilter);
    props.onFilterUpdated(newFilter);
  };

  return (
    <div>
      <ToggleButtonGroup value={filter} onChange={handleFilter} aria-label="source filter" exclusive>
        <ToggleButton value='skills' aria-label="Skills">
        📚Skills
        </ToggleButton>
        <ToggleButton value='jobs' aria-label="Jobs">
        💼Jobs
        </ToggleButton>
        <ToggleButton value="intelligences" aria-label="Intelligences">
        🧠Intelligences
        </ToggleButton>
        <ToggleButton value='tags' aria-label="Tags">
        🏷️Tags
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
