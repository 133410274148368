import React from 'react';

import './SearchBar.css';

import skills from './skills.json';
import categories from './skill-categories.json';

// import Procificency from './Proficiency'; Removing for basic version of the search bar
// import User from './User';

class SearchBar extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      search: "",
      user: props.user ? props.user : {},
      activeSkillIndex: 0,
      filteredSkills: [],
      showSkills: false
    };
  }

  componentDidMount() {
    // new User().load().then(user => {
    //   this.setState({user: user});
    // })
    this.fetchPublicSkills()
  }

  fetchPublicSkills () {
    let sortedSkills = skills.sort((a, b) => a.name.localeCompare(b.name));
    this.publicSkills = sortedSkills.filter( s => s.public );
  }


  handleClose = () => {
    this.setState(
      prevState => {
        return {
            search: "",
            filteredSkills: [],
            activeSkillIndex: 0
        }
      }
    )
  }

  handleChange = (event) => {
    
    if (event.target.value === "") {
      this.handleClose();
    }

    this.setState({
      search: event.target.value,
      activeSkillIndex: 0
    },()=>{
        let filteredSkills  = this.publicSkills && this.publicSkills.filter(s => s.name.toLowerCase().includes(this.state.search.toLowerCase()));
        this.setState({
          filteredSkills: filteredSkills || [],
          showSkills: filteredSkills && filteredSkills.length ? true : false
        })        
    });

  }

  renderSkillCategories(skill) {
    if (!skill.categories) return "";

    return skill.categories.map(c =>
      categories.hasOwnProperty(c) && categories[c].isActive &&
      <a key={c} href={`/index?category=${c}`} className="label no-padding tooltip dot" style={{backgroundColor: categories[c].color}}>
        <span className="tooltiptext">{c}</span>
      </a>
    )
  }

  onKeyDown = (e) => {
    //console.log(e.keyCode)
    if (e.keyCode === 13) {

      this.setState({
        activeSkillIndex: 0,
        showSkills: false,
        search: this.state.filteredSkills[this.state.activeSkillIndex].name
      });

    } else if (e.keyCode === 38) {

      if (this.state.activeSkillIndex === 0) {
        return;
      }
      this.setState({ activeSkillIndex: this.state.activeSkillIndex - 1 }, ()=> {
        this.scrollSelectedSkill();
      });
      
    } else if (e.keyCode === 40) {
      
      if (this.state.activeSkillIndex  === this.state.filteredSkills.length - 1) {
        return;
      }
      
      this.setState({ activeSkillIndex: this.state.activeSkillIndex + 1 }, ()=> {        
        this.scrollSelectedSkill();
      });
    }

  }

  scrollSelectedSkill = ()=> {

    if(this.state.activeSkillIndex) {
      this.searchAnchorrefs[this.state.activeSkillIndex] && this.searchAnchorrefs[this.state.activeSkillIndex].focus();
      this.searchInputRef.focus();
    }      
    
  }

  searchAnchorrefs = []

  render() {
    return (
      <div>
        <h1 style={{color: this.props.theme ? this.props.theme : 'black'}}>I want to learn...</h1>
        <div>
          <div className="border">
            🔍 <input 
                  type="text" 
                  className={`search-input no-border ${this.props.theme === "white" ? "transparent" : ""} ${this.props.theme}`} 
                  onChange={this.handleChange} 
                  onKeyDown={this.onKeyDown}
                  value={this.state.search} 
                  ref={ x => this.searchInputRef = x }
                />
            <span className={`close ${this.state.search !== "" && "active"}`} onClick={this.handleClose}>&times;</span>
          </div>
        </div>
        <div>
        {
        this.state.showSkills && this.state.search !== '' &&
          <div ref={this.modalRef} className={`modal ${this.state.search !== "" && "active"}`}>
            <div className="modal-content">
              <ul>
                {
                  this.state.filteredSkills.map( (s, index) => {

                      return ( <li style={{backgroundColor: index === this.state.activeSkillIndex? "gray": "white" }} key={s._id}>
                        {/* <Procificency user={this.state.user} skillId={s._id} />&nbsp; Removing for basic version */}
                        <a  ref={ x => this.searchAnchorrefs[index] = x }
                          href={'/skill/' + s.name.toLowerCase().replaceAll('-', '').replaceAll(' ', '-')}
                        >
                          {s.name}
                        </a>
                        <div style={{float: 'right'}}>
                          {this.renderSkillCategories(s)}
                        </div>
                      </li> );

                    } 
                    
                  )
                }
              </ul>
            </div>

          </div>
        }
        </div>
      </div>
    )
  }
}

export default SearchBar;