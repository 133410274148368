import React from 'react'

class PieChart extends React.Component {

  state = {
    proficiencies: this.props.proficiencies
  }
	
  componentDidMount() {
    this.updateCanvas();    
  }

  componentDidUpdate(prevProps) {
    if (prevProps.proficiencies !== this.props.proficiencies) {
      this.updateCanvas();
    } else if (prevProps.updateChart !== this.props.updateChart) {
      this.updateCanvas();
    }
  }

  updateCanvas() {
    
    this.ctx = this.canvasRef.getContext('2d');
    this.ctx.lineWidth = 2;
    this.ctx.font = '14px verdana';

    this.PI2 = Math.PI * 2;
    var data = this.props.data;
    let temp = []
    let canvasHide = true;
    for(let i in data) {
      if(data[i].percentage) {
        temp.push(data[i])
        canvasHide = false;
      }
    }

    if(canvasHide) {
      this.canvasRef.style.display = "none";
      return;
    } else {
      this.canvasRef.style.display = "";
    }

    data = temp;

    this.cx = this.props.width/2;
    this.cy = this.props.height/2;
    this.radius = (this.props.width/2) - 10;

    if(data.length) {
      this.pieChart(data);	
    } else {
      // this.ctx.textAlign = 'center';
      var textString = "No Data Found!!",
      textWidth = this.ctx.measureText(textString ).width;
      this.ctx.beginPath();
      this.ctx.arc(this.cx, this.cy, this.radius, 0, 2 * Math.PI, false);
      this.ctx.fillStyle = 'white';
      this.ctx.fill();
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = 'gray';
      this.ctx.stroke();
      this.ctx.fillStyle = 'red';
      this.ctx.fillText(textString, this.cx - (textWidth / 2), this.cy);
    }  

    this.canvasRef.addEventListener('click', this.canvasClickHandler);
    this.canvasRef.addEventListener('mousemove', this.canvasMouseMoveHandler);
   
  }

  canvasMouseMoveHandler = ( e ) => {

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    const pos = {
      x: e.clientX - this.canvasRef.offsetLeft + scrollLeft,
      y: e.clientY - this.canvasRef.offsetTop + scrollTop 
    };

    if(this.isIntersect(pos, {x: this.cx, y: this.cy, radius: this.radius}))
      this.canvasRef.style.cursor = "pointer";
    else
      this.canvasRef.style.cursor = "default";

  }

  isIntersect = (point, circle) => {
    return Math.sqrt((point.x-circle.x) ** 2 + (point.y - circle.y) ** 2) < circle.radius;
  }

  canvasClickHandler = ( e ) => {
  	
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

   	const mousePos = {
      x: e.clientX - this.canvasRef.offsetLeft + scrollLeft,
      y: e.clientY - this.canvasRef.offsetTop + scrollTop 
    };	  

    // get pixel under cursor
    const pixel = this.ctx.getImageData(mousePos.x, mousePos.y, 1, 1).data;
    const hexColor = this.rgbToHex(pixel[0], pixel[1], pixel[2]);
    for(let i in this.props.data) {
      if(this.props.data[i].color === hexColor) {
        if(this.props.data[i].link)
          window.location = this.props.data[i].link
        break;
      }
    }
  }

  componentToHex = (c) => {
    var hex = c.toString(16);

    return hex.length === 1 ? "0" + hex : hex;
  }

  rgbToHex = (r, g, b) => {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  pieChart = (data) => {
    var total = 0, i;
    for (i = 0; i < data.length; i++) {
      total += data[i].percentage;
    }

    var sweeps = []
    for (i = 0; i < data.length; i++) {
      sweeps.push(data[i].percentage / total * this.PI2);
    }

    var accumAngle = 0;
    for (i = 0; i < sweeps.length; i++) {
      this.drawWedge(accumAngle, accumAngle + sweeps[i], data[i].color, data[i].percentage);
      accumAngle += sweeps[i];
    }
  }

  drawWedge = (startAngle, endAngle, fill, label) => {
    // draw the wedge
    this.ctx.beginPath();
    this.ctx.moveTo(this.cx, this.cy);
    this.ctx.arc(this.cx, this.cy, this.radius, startAngle, endAngle, false);
    this.ctx.closePath();
    this.ctx.fillStyle = fill;
    this.ctx.strokeStyle = 'white';
    this.ctx.fill();
    this.ctx.stroke();    
  }

	render() {
    return (
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        // FOR mobile ??
        // alignItems: 'center',
        // justifyContent: 'center'
      }}>
        <div style={{
          padding: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>  
          <div>
            {
              this.props.data.map((params, index) => {
                return params.percentage? <div 
                  key={"label" + index}
                  style={{
                  }}
                >
                  <div style={{
                    backgroundColor: params.color,
                    width: 10,
                    height: 10,
                    marginRight: 10,
                    display: 'inline-block'
                  }} />
                  <span>
                    {params.label} &nbsp; 
                    (                       
                      <a 
                        href={params.link} 
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        { params.percentage + "%"}
                      </a> 
                    )
                  </span>
                </div> : null
              })
          }
          </div>
        </div>
        <canvas
          ref={ x => this.canvasRef = x }  
          width={this.props.width}
          height={this.props.height}
          style={{
          }}
        />
      </div>
    );
  }
}

PieChart.defaultProps = {
  width: 300,
  height: 300,
};

export default PieChart;