import skills from './skills.json';
import convertArrayToObject from './utilities';
import jobs from './jobs.json';
import categories from './skill-categories.json';
import families from './skill-families.json';
import blogs from './blog.json';

const skillsSet = convertArrayToObject(skills, '_id');

const exportedObject = {
	skills: skills,
	skillsSet: skillsSet,
	jobs: jobs,
	categories: categories,
	families: families,
	blogs: blogs
}
export default exportedObject;