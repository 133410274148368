import * as React from 'react';
import Proficiency from './Proficiency';
import skills from './skills.json';
import categories from './skill-categories.json';
import families from './skill-families.json';
import CustomXGridContainer from './components/CustomXGridContainer';
import CustomXGridTheme from './components/CustomXGridContainer/Theme';
import star from './img/star.png';
import starred from './img/starred.png';
import User from './User';

skills.sort((a, b) => a.name.localeCompare(b.name));

class SkillsGrid extends CustomXGridContainer {

  state = {
    width: window.innerWidth,
    filterModel: [],
    gridData: {
      columns:[],
      rows: []
    }
  }

  constructor(props) {
    super(props);
    
    this.columns = [
      {field: 'id', hide: true},
      {field: 'favorite', headerName: '⭐', width: 50, type:'number', renderCell: this.renderSkillFavorite, disableSelectionOnClick: true},
      {field: 'proficiency', headerName: 'Proficiency', type:"number", width: 65, renderCell: this.renderSkillProficiency, disableSelectionOnClick: true},
      {field: 'skill', headerName: 'Skill', width: this.skillColumnWidth(), renderCell: this.renderGridSkill, disableSelectionOnClick: true},
      {field: 'purpose', headerName: 'Purpose', width: 105, renderCell: (params) => this.renderPurpose(params, this.handlePurposeDotClick, this)},
      {field: 'categories', headerName: 'Intelligences', width: 135, renderCell: (params) => this.renderSkillCategories(params, this.handleCategoryDotClick, this)},
      {field: 'resources', headerName: '# Resources', type: 'number', width: 135},
      {field: 'benefits', headerName: '# Benefits', type: 'number', width: 120},
      {field: 'families', headerName: 'Tags', flex: 1, renderCell: this.renderTags}
    ]
  }
  
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);    
    new User().load().then(user => {
      this.setState({user: user}, () => {
        this.setState({
          gridData: this.getGridData()
        })
      });
    });
  }

  getGridData = () => {    
    const data = this.getData();
    return data;
  } 

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  skillColumnWidth = () => {
    return this.state.width < 850 ? 150 : 250;
  }  
  
  getData() {
    return {
      columns: this.columns,
      rows: this.getRows()
    }
  }
  
  getRows() {
    const acceptedCategories = Object.values(categories).filter(c => c.isActive).map(c => c.name);
    const skillList = this.props.hasOwnProperty('skills') ? this.props.skills : skills
    const publicSkills = skillList.filter(s => s && s.public);

    return publicSkills.map(skill => {

      return {
        id: skill._id,
        favorite: this.state.user ? (this.state.user.data.favorites.items.findIndex(i => i.skillId === skill._id) > -1 ? 1 : 0) : 0,
        proficiency: this.state.user ? this.getMastery(skill) : 0,
        skill: skill.name,
        purpose: skill.hasOwnProperty('purpose') ? skill.purpose : [],
        categories: skill.hasOwnProperty('categories') ? skill.categories.filter(c => acceptedCategories.includes(c)) : [],
        resources: skill.hasOwnProperty('resources') ? skill.resources.length : [],
        benefits: this.getNumBenefits(skill),
        families: skill.hasOwnProperty('families') ? skill.families.join(', ') : '',
        jobs: skill.hasOwnProperty('jobs') ? skill.jobs : []
      };
    });
  }

  getMastery(skill) {
    const mastery = this.state.user.data.mastery.items.find(i => i.skillId === skill._id);

    if (mastery) return mastery.proficiency;

    return 0;
  }
  
  getNumBenefits(skill) {
    let numBenefits = 0;
    
    if (skill.hasOwnProperty('benefits')) numBenefits += skill.benefits.length;
    
    if (skill.hasOwnProperty('families')) {
      skill.families.forEach(f => numBenefits += families[f].benefits.length);
    }
    
    return numBenefits;
  }
  
  cellClicked = (param) => {
    const functions = {
      'favorite': this.updateFavorite,
      'proficiency': this.updateProficiency
    };
    
    if (!functions.hasOwnProperty(param.field)) return;
    
    functions[param.field](this, param);
  }

  updateFavorites = ( skillId ) => {
    for(let i in this.state.gridData.rows) {
      let skill = this.state.gridData.rows[i];
      if(skill.id === skillId) {
        skill.favorite = this.state.user ? (this.state.user.data.favorites.items.findIndex(i => i.skillId === skill.id) > -1 ? 1 : 0) : 0
        break; 
      }      
    }

    this.setState({
      gridData: this.state.gridData
    })
  }
  
  updateFavorite(caller, param) {
    if (!caller.state.user) return;

    const skillId = param.row.id;
    const user = caller.state.user;
    const index = user.data.favorites.items.findIndex(i => i.skillId === skillId);
    if (index !== -1) {
      user.deleteFavorite(user.data.favorites.items[index].id, index).then(() => {
        caller.setState({user: user},()=> {
          caller.updateFavorites(skillId);
        });
      });
    } else {
      user.addFavorite(skillId).then(() => {
        caller.setState({user: user},()=>{
          caller.updateFavorites(skillId);
        });
      });
    }
  }

  updateProficiencies = (skillId)=> {
    for(let i in this.state.gridData.rows) {
      let skill = this.state.gridData.rows[i];
      if(skill.id === skillId) {
        skill._id = skill.id;
        skill.proficiency = this.state.user ? this.getMastery(skill) : 0; 
        break; 
      }      
    }

    this.setState({
      gridData: this.state.gridData
    })
  }
  
  updateProficiency(caller, param) {
    if (!caller.state.user) return;

    const skillId = param.row.id;
    const user = caller.state.user;

    let level = param.row.proficiency;
    level++;
    if (level > 4) level = 0;

    user.updateMastery(skillId, level).then(() => {
      caller.setState({user: user},()=>{caller.updateProficiencies(skillId);});
    });
  }

  updateColumnOrder = ( newColumnsOrder ) => {
    let orderedColumn = []
    newColumnsOrder.map(col=>{
      for(let c in this.columns) {
        if(this.columns[c].headerName === col) {
          orderedColumn.push(this.columns[c]);
          break;
        }
      }

      return col;
    })
    // add id column
    orderedColumn.splice(0,0,this.columns[0]);
    this.columns = orderedColumn;    
    this.setState({
      renderTimeStamp: Date.now()
    })
  }
  
  //
  // RENDER
  render() {
    return (
      <div style={{width: '100%' }}>
        {this.renderGrid(this.state.gridData, this.cellClicked, this.state.filterModel, {
              pagination: true,
              pageSize: 50})}
      </div>
    );
  }

  renderSkillFavorite(params) {
    const img = params.value === 1 ? starred : star;

    return <div className="fullHeight" style={{paddingTop: 5}}><img alt={params.value === 1? "Favorite" : ""} src={img} /></div>
  }

  renderSkillProficiency(params) {
    return <div className="fullHeight"><Proficiency level={params.value} /></div>
  }
  
  renderGridSkill(params) {
    return <div className="fullHeight">
      <a href={`/skill/${encodeURIComponent(params.value)}`}>{params.value}</a>
    </div>
  }
  
  handleCategoryDotClick(e, params, caller){
    e.target.setAttribute('data-filter-click', true);

    const spanCategory = params.value[e.target.getAttribute('data-index')];
    
    // If this filter of category already exists, then do nothing
    if (caller.state.filterModel.find(filter =>
      filter.columnField === 'categories' && filter.operatorValue === 'contains' && filter.value === spanCategory)) {
        return;
      }
    
      caller.setState(({filterModel}) => ({
      filterModel: filterModel.concat([
        {
          columnField: 'categories',
          operatorValue: 'contains',
          value: spanCategory
        }]
      )
    }))
  }

  handlePurposeDotClick(e, params, caller){
    console.log('here');
    e.target.setAttribute('data-filter-click', true);

    const spanPurpose = params.value[e.target.getAttribute('data-index')];
    
    // If this filter of purpose already exists, then do nothing
    if (caller.state.filterModel.find(filter =>
      filter.columnField === 'purpose' && filter.operatorValue === 'contains' && filter.value === spanPurpose)) {
        return;
      }
    
      caller.setState(({filterModel}) => ({
      filterModel: filterModel.concat([
        {
          columnField: 'purpose',
          operatorValue: 'contains',
          value: spanPurpose
        }]
      )
    }))
  }
  
  renderSkillCategories(params, handleCategoryDotClick, caller) {
    
    return <div className="fullHeight" style={{
      paddingTop: 5
    }}>
      {
        params.value.map((c, index) =>
          <img 
            key={c}
            data-index={index}
            src={`${process.env['PUBLIC_URL']}/${c.replace('/', '-')}@72x.png`}
            alt={c}
            width='18'
            onClick={(e) => handleCategoryDotClick(e, params, caller)}
            />
        // <div
        //   key={c}
        //   data-index={index}
        //   className="label no-padding tooltip dot pointer"
        //   style={{
        //     backgroundColor: categories[c].color,
        //     float: 'left'
        //   }}
        //   onClick={(e) => handleCategoryDotClick(e, params, caller)}
        // >
        //   {/* {c}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
        // </div>
        )
      }
    </div>;
  }
  
  renderPurpose(params, handlePurposeDotClick, caller) {
    const map = {
      'professional': "👔",
      'personal': "🧑"
    };
    
    return params.value.map((p, index) => 
      <span
        key={p}
        data-index={index}
        className="pointer fullHeight"
        onClick={(e) => handlePurposeDotClick(e, params, caller)}
      >
        {map[p]}
      </span>
    );
  }

  renderTags = (params) => {
    return <div className="fullHeight">
      {
        params.value.split(",").sort().map((tagName, index) => 
          <div style={{
              float: 'left'
            }}
            key={"tagName"+index}
          >
            <a href={`/tag/${encodeURIComponent(tagName.trim())}`}>{this.titleCase(tagName.trim())}</a>&nbsp; &nbsp;
          </div>
        )
      }
    </div>
  }

  renderJobs(params) {
    return <div className="fullHeight">
      {
        params.value.sort().map((jobName,index) => 
          <div style={{
              float: 'left'
            }}
            key={"jobcontainer"+index}
          >
            <a href={`/job/${encodeURIComponent(jobName)}`}>{jobName}</a>&nbsp; &nbsp;
          </div>
        )
      }
    </div>
  }

}

export default CustomXGridTheme(SkillsGrid);
