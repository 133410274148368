import React from 'react';
import './Skill.css';
import convertArrayToObject from './utilities';
import skills from './skills.json';
import categories from './skill-categories.json';
import families from './skill-families.json';
import User from './User';
import SearchBarBasic from './SearchBarBasic.js';
import SkillBackground from './SkillBackground.js';
import SkillResources from './SkillResources.js';
import Header from './components/Header';
import RelatedSkills from './RelatedSkills.js';
import GoogleTrends from './components/GoogleTrends';
import AddToFavorite from './components/AddToFavorite';
import ProficiencyBar from './components/ProficiencyBar';
import { isIOS, isSafari } from "react-device-detect";

class Skill extends React.Component {
  constructor(props) {
    super(props);
    this.skillName = decodeURIComponent(this.props.match.params.skill);
    this.state = {};
    this.isSkillUp = window.location.hostname.includes('skillup');
  }

  componentDidMount() {
    document.title = "Learn " + this.titleCase(this.skillName.replaceAll('-', ' ')) + " on " +  (this.isSkillUp ? "SkillUp Academy" : "Skills DB");

    new User().load().then(user => {      
      let favorite = user? (user.data.favorites.items.findIndex(i => i.skillId === this.skillObject._id) > -1 ? true : false) : false;    
      this.setState({
        favorite: favorite,
        user: user
      })
    });
  }

  updateFavorite = (param) => {
    if (!this.state.user) return;

    const skillId = this.skillObject._id;
    const user = this.state.user;

    const index = user.data.favorites.items.findIndex(i => i.skillId === skillId);
    if (index !== -1) {
      user.deleteFavorite(user.data.favorites.items[index].id, index).then(() => {
        this.setState({user: user, favorite: false});
      });
    } else {
      user.addFavorite(skillId).then(() => {
        this.setState({user: user, favorite: true});
      });
    }
  }


  //
  // Class methods

  updateUser = (user) => {
    this.setState({user: user});
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }


  //
  // Rendering

  render() {
    let objects = convertArrayToObject(skills, 'name');
    for (const key in objects) {
      objects[objects[key].name.toLowerCase().replaceAll('-', '').replaceAll(' ', '-')] = objects[key];
    }

    var name = this.skillName;

    if (!objects.hasOwnProperty(name)) {
      return (
        <div>
          <Header />
          <div className="content-wrapper">
            <h2>{this.titleCase(name.replaceAll('-', ' '))} isn't yet supported.</h2>
            <p>Okay, then...</p>
            <SearchBarBasic openSkillpage={true} />
          </div>
        </div>
      )
    }

    const skill = objects[name];
    this.skillObject = skill;

    return (
      <div className="light-grey-bg">
        <Header color='white' />
        <div className="content-wrapper">
          <SkillBackground skill={skill} user={this.state.user} />

          <section className="page-section container" style={{marginTop: '33px'}}>
            {this.renderSummary(skill)}
            {/* {this.renderTools(skill)} */}
            
            {/* {skill.hasOwnProperty('process') && <TableContents user={this.state.user} skill={skill} />} */}
            {/* <JournalEntries user={this.state.user} skillId={skill._id} /> */}

            {/* {this.state.user && ["15", "100", "1,000"].map(time => <SkillDetails key={time} time={time} name={skill.name} skill={skill} user={this.state.user} updateUser={this.updateUser.bind(this)} />)} */}

          </section>
          {this.renderBenefits(skill)}<br />
          {this.renderMeta(skill)}
          {this.renderAssessments(skill)}
          <br />
          {skill.hasOwnProperty('relatedSkills') && skill.relatedSkills.length > 0 &&
            <>
              <div className="dashboard-card">
                <RelatedSkills skill={skill} user={this.state.user} skills={skills} />
              </div><br />
            </>
          }
          {this.renderJobs(skill)}<br />
          <SkillResources skill={skill} />
        </div>
        <br /><br />
        <hr />
        <div style={{width: '80%', margin: '0px auto'}}>
        <SearchBarBasic openSkillpage={true} />
        </div>
      </div>
    );
  }

  renderSummary(skill) {
    const benefits = this.getBenefits(skill)
    const skillCategories = skill.hasOwnProperty('categories') ? skill.categories.filter(c => categories.hasOwnProperty(c) && categories[c].isActive) : []
    const relatedSkillSummary = this.renderSummaryRelatedSkills(skill)

    return (
      <div className="dashboard-card" style={{marginTop: '-30px'}}>
        <AddToFavorite favorite={this.state.favorite} updateFavorite={this.updateFavorite} /><br />
        {this.renderProficiency(skill._id)}<br />
        <p>
          {skill.purpose.length > 0 && skillCategories.length > 0 && <span>The {skill.name} skill is mainly used for {skill.purpose.join(' and ')} purposes and touches on the {skillCategories.join(' & ')} intelligence{skillCategories.length > 1 && <span>s</span>}.&nbsp;</span>}
          {skill.hasOwnProperty('families') && skill.families.length > 0 && <span>It's part of the {skill.families.map((family) => this.titleCase(family)).join(' & ')} family. </span>}
          {benefits.length > 0 && <span>There are <a href="#benefits">{benefits.length} benefits</a> to learn it, including: "{benefits[0].replace('{skill_name}', skill.name)}". </span>}
          {skill.hasOwnProperty('resources') && skill.resources.length > 0 && <span>We have <a href="#resources">{skill.resources.length} resources</a> for it. </span>}
          {relatedSkillSummary}
          {relatedSkillSummary !== '' && skill.hasOwnProperty('jobs') && skill.jobs.length > 0 && 
            <span> and the <a href={`/job/${skill.jobs[0]}`}>{skill.jobs[0]}</a> job.</span>
          }
        </p>
      </div>
    )
  }

  renderSummaryRelatedSkills(skill) {
    if (!skill.hasOwnProperty('relatedSkills') || skill.relatedSkills.length > 0)
      return ''

    let skillObj
    if (typeof skill.relatedSkills[0] === 'string') {
      skillObj = skills.find((s) => s._id === skill.relatedSkills[0])
    } else if (typeof skill.relatedSkills[0] === 'object') {
      skillObj = skills.find((s) => s._id === skill.relatedSkills[0].id)
    }

    if (!skillObj)
      return ''

    return <span>
              <br /><br />
              You might also be interested in the <a href={`/skill/${skillObj.name}`}>{skillObj.name}</a> skill{(!skill.hasOwnProperty('jobs') || skill.jobs.length === 0) && <span>.</span>}
          </span>
  }

  renderMeta(skill) {
    return (
      <div className="dashboard-card">
        <h2 className="dashboard-title">Metadata</h2>
        <table>
          <tbody>
            {this.renderPurpose(skill)}
            {this.renderCategories(skill)}
            {this.renderFamilies(skill)}
          </tbody>
        </table>
        <br />
        <div id="widget">
          {
            isSafari && !isIOS &&
            <div>
              If the line chart below doesn't show up, try the following: <a target="_blank" rel="noreferrer" href="https://support.apple.com/en-ca/guide/safari/sfri40732/mac">Allow cross-site tracking in Safari on Mac</a>
            </div>
          }
          {
            isIOS &&
            <div>
              If the line chart below doesn't show up, try the following: <a target="_blank" rel="noreferrer" href="Prevent Cross-Site Tracking">Allow cross-site tracking in Safari on iOS</a>
            </div>
          }
          <GoogleTrends
            type="TIMESERIES"
            keyword={skill.name}
            url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
          />
        </div>
      </div>
    )
  }

  renderCategories(skill) {
    if (!skill.categories) return null;

    return (
      <tr>
        <td style={{verticalAlign: 'top', paddingTop: '10px', paddingBottom: '25px'}}>Intelligences: </td>
        <td>
        {
          skill.categories.map((c, index) =>
            categories.hasOwnProperty(c) && categories[c].isActive &&
            <a 
              href={"/intelligence/" + encodeURIComponent(c)}
              key={c}
              style={{marginRight: '5px'}}
            >
              <img 
                key={c}
                data-index={index}
                src={`${process.env['PUBLIC_URL']}/${c.replace('/', '-')}@72x.png`}
                alt={c}
                width='36'
                />
            </a>
          )
        }
        </td>
      </tr>
    );
  }

  renderFamilies(skill) {
    if (!skill.families) return null;

    return (
      <tr>
        <td>Tags: </td>
        <td>
        {
          skill.families.map(c =>
            <a
              href={"/tag/" + encodeURIComponent(c)}
              key={c}
              className="label category-block"
              style={{
                backgroundColor: 'orange',
                cursor: "pointer",
                textDecoration: 'none'
              }}              
            >
              <span className="no-underline" style={{color: 'white'}}>
                {this.titleCase(c)}&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </a>
          )
        }
        </td>
      </tr>
    );
  }

  renderPurpose(skill) {
    if (!skill.purpose) return null;

    const colors = {
      'professional': 'black', 
      'personal': 'orange'
    };

    return (
      <tr>
        <td>Purpose: </td>
        <td>
        {
          skill.purpose.map(p => 
            <span key={p} className="label no-underline margin5" style={{backgroundColor: colors[p], cursor: "default"}}>{this.titleCase(p)}&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )
        }
        </td>
      </tr>
    )
  }

  renderTools(skill) {
    return (
      <div>
        <h2>Tools</h2>
        <ul>
          <li><a href={`/me/reflection/new?id=${skill._id}`} className="no-underline padding5">📝 Reflection Journal</a></li>
        </ul>
      </div>
    );
  }

  renderProficiency(skillId) {
    return (
      <div>
        <div>
          <strong>Your proficiency: </strong>
          <ProficiencyBar user={this.state.user} skillId={skillId} noTooltip={true} />
        </div>
      </div>
    );
  }

  getBenefits(skill) {
    if (!skill.hasOwnProperty('benefits') && !skill.hasOwnProperty('families')) return []

    let benefits = []

    if (skill.hasOwnProperty('families'))
      skill.families.forEach(f => families[f].benefits.forEach(b => benefits.push(b)));

    if (skill.hasOwnProperty('benefits'))
      skill.benefits.forEach(b => benefits.push(b));

    return [...new Set(benefits)]
  }

  renderBenefits(skill) {
    if (!skill.hasOwnProperty('benefits') && !skill.hasOwnProperty('families')) return "";

    const list = this.getBenefits(skill).map(b => <li key={b}>{b.replace('{skill_name}', skill.name)}</li>);

    return (
      <div className="dashboard-card">
        <h2 className="dashboard-title" id="benefits">Benefits of learning <strong>{skill.name}</strong></h2>
        <ul>
            {list}
        </ul>
      </div>
    )
  }

  renderAssessments(skill) {
    if (!skill.hasOwnProperty('assessments')) return "";
    if (skill.assessments.length === 0) return "";

    return (
      <div>
        <br />
        <div className="dashboard-card">
          <h2 className="dashboard-title" id="assessments"><strong>{skill.name}</strong> Assessments</h2>
          <ul>
            {
              skill.assessments.map(a => 
                <li key={a.url}><a href={a.url} target="_blank" rel="noopener noreferrer">{a.name}</a></li>  
              )
            }
          </ul>
        </div>
      </div>
    )
  }

  renderJobs(skill) {
    if (!skill.hasOwnProperty('jobs')) return "";

    return (
      <div className="dashboard-card">
        <h2 id="jobs" className="dashboard-title"><strong>{skill.name}</strong> Related Jobs</h2>
        <ul>
          {
            skill.hasOwnProperty('jobs') && skill.jobs.map(j => 
              <li key={j}>
                <a href={'/job/'+encodeURIComponent(j)} >{j}</a>
              </li>  
            )
          }
        </ul>
      </div>
    )
  }
}

export default Skill;
