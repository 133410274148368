import React from 'react';
import ReactPlayer from 'react-player';

import bgImage from '../../img/fireworks.jpg';
import bgImageNetwork from '../../img/network.png';

import './Background.css';

class Background extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      pageLoaded: false
    };

    this.isSkillUp = window.location.hostname.includes('skillup');
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('load', this.handleLoad);
  }

  componentWillUnmount() { 
    window.removeEventListener('resize', this.handleResize)  
    window.removeEventListener('load', this.handleLoad)  
  }

  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  handleLoad = () => {
    this.setState({pageLoaded: true});
  }

  renderImage() {
    const image = this.isSkillUp ? bgImage : bgImageNetwork;
    return <div className="bg-image" style={{backgroundImage: `url(${image})`}} />
  }

  renderBackground() {
    if (!this.state.pageLoaded || this.state.width < 1350 || !this.isSkillUp) 
      return this.renderImage();

    return <ReactPlayer url='https://youtu.be/MTtqa93KGds' width="100%" height="100%" playing={true} loop={true} muted={true} playbackRate={1.0} />
  }

  render() {
    return (
      <div className="section-background">
        {this.renderBackground()}
      </div>
    );
  }
}

export default Background;