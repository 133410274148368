import * as React from 'react';
import skills from './skills.json';
import jobs from './jobs.json';
import User from './User';
import CustomXGridContainer from './components/CustomXGridContainer';
import CustomXGridTheme from './components/CustomXGridContainer/Theme';
import convertArrayToObject from './utilities';

const publicSkills = skills.filter(s => s.public);

class JobsGrid extends CustomXGridContainer {
  constructor(props) {
    super(props);
    
    this.state = {
      width: window.innerWidth,
      filterModel: [],
      skills: convertArrayToObject(publicSkills, '_id'),
      gridData: {
        columns:[],
        rows: []
      }
    };
  }  
  
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);

    new User().load().then(user => {
      this.setState({user: user});
    });
    const data = this.getData();
    this.setState({
      gridData: data
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  
  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }
  
  getData() {
    return {
      columns: [
        {field: 'id', headerName: 'Id', hide: true},
        {field: 'job', headerName: 'Job', width: 250, renderCell: (params) => <div className="fullHeight"><a href={`/job/${params.value}`}>{params.value}</a></div>},
        {field: 'skills', headerName: "Related Skills", flex: 1, width: 200, renderCell: this.renderSkills}
      ],
      rows: this.getRows()
    }
  }
  
  getRows() {           
    return Object.values(jobs).sort((a, b) => a.name.localeCompare(b.name)).map((job, index) => {
      return {
        id: index,
        job: job.name,
        skills: job.hasOwnProperty('skills') ? job.skills.map(
          s => this.state.skills[s.id] && this.state.skills[s.id].name
        ).sort((a, b) => a.localeCompare(b)).join(', ') : ''
      };
    });
  }

  cellClicked = ()=> {}
  
  //
  // RENDER
  
  render() {
    return (
      <div style={{width: '100%' }}>
        {this.renderGrid(this.state.gridData, this.cellClicked, this.state.filterModel, {pagination: true, pageSize: 50})}
      </div>
    );
  }

  renderSkills = (params) => {
    return <div className="fullHeight">
      {
        params.value.split(', ').map((skillName,index) => 
          <div style={{
              float: 'left'
            }}
            key={"skillcontainer"+index}
          >
            <a href={`/skill/${skillName}`}>{this.titleCase(skillName)}</a>&nbsp; &nbsp;
          </div>
        )
      }
    </div>
  }
  
}

export default CustomXGridTheme(JobsGrid);