import {Switch, Route} from 'react-router-dom';

import './App.css';
import Home from  './Home.js';
import Explore from './Explore';
import SkillIndex from './SkillIndex.js';
import Skill from './Skill.js';
import Business from './components/Business/Business';
import JobIndex from './JobIndex';
import Job from './screens/Job';
import Intelligence from './screens/Intelligence';
import Tag from './screens/Tag';
import Contribute from './screens/Contribute';
import Footer from './components/Footer';
import PreFooter from './components/Footer/PreFooter';
import TermsAndConditions from './screens/TermsAndConditions';
import Privacy from './screens/Privacy';
import Blog from './screens/Blog/Blog';
import Courses from './screens/Courses';
import Tools from './screens/Tools';
import BlogIndex from './screens/Blog/BlogIndex';
import SkillCategories from './SkillCategories';
import MyProfile from './screens/Me/MyProfile';
import ReflectionJournal from './screens/Me/ReflectionJournal';
import FAQ from './screens/FAQ';
import SignIn from './screens/SignIn';
import SignOut from './screens/SignOut';

import { LicenseInfo } from '@material-ui/x-grid';
import Contact from './screens/Contact';

LicenseInfo.setLicenseKey(
  '2db2c873bdeba70ab5f4e3922ac4ca1dT1JERVI6MjE4OTMsRVhQSVJZPTE2NDYxMzg4NzgwMDAsS0VZVkVSU0lPTj0x',
);

function App() {
  
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/explore" component={Explore}/>
        <Route path="/index" component={SkillIndex}/>
        <Route path="/skills" component={SkillIndex}/>
        <Route path="/me/reflection/:status" component={ReflectionJournal}/>
        <Route path="/me/reflection/" component={ReflectionJournal}/>
        <Route path="/me" component={MyProfile}/>
        <Route path="/categories" component={SkillCategories}/>
        <Route path="/contribute" component={Contribute}/>
        <Route path="/courses" component={Courses}/>
        <Route path="/tools" component={Tools}/>
        <Route path="/terms-and-conditions" component={TermsAndConditions}/>
        <Route path="/privacy" component={Privacy}/>
        <Route path="/skill/:skill" component={Skill}/>
        <Route path="/business/:business" component={Business}/>
        <Route path="/jobs" component={JobIndex}/>
        <Route path="/job/:job" component={Job}/>
        <Route path="/intelligence/:intelligence" component={Intelligence}/>
        <Route path="/tag/:tag" component={Tag}/>
        <Route path="/blog/:blog" component={Blog}/>
        <Route path="/blog" component={BlogIndex}/>
        <Route path="/faq" component={FAQ}/>
        <Route path="/signin" component={SignIn} />
        <Route path="/signout" component={SignOut} />
        <Route path="/contact" component={Contact} />
      </Switch>
      <PreFooter />
      <Footer />
    </div>
  );
}

export default App;
