import React from 'react';
import './Blog.css';
import Header from '../../components/Header';
import blog from '../../blog.json';
import CustomXGridContainer from '../../components/CustomXGridContainer';
import CustomXGridTheme from '../../components/CustomXGridContainer/Theme';
import SearchBarBasic from '../../SearchBarBasic.js';
const queryString = require('query-string');

class BlogIndex extends CustomXGridContainer {

  state = {   
    gridData: {
      columns:[],
      rows: []
    } 
  }

  tagList=[]

  constructor(props) {
    super(props);
    const qs = queryString.parse(this.props.location.search);
    this._tag = qs.tag;
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = "Blog";
    this.setState({
      gridData: this.getData()
    })
  }

  getData() {
    return {
      columns: [
        {field: 'id', hide: true},
        {field: 'title', headerName: 'Blog List', flex: 0.6, renderCell: (params) =>this.renderTitle(params, this)}
      ],
      rows: this.getRows()
    }
  }

  getRows() {
    
    return Object.values(blog).filter(b => {
      if(!this.tagList)
        this.tagList = [];

      b.tags.map(tag=>{
        if(this.tagList.indexOf(tag) === -1) {
          this.tagList.push(tag)
        }

        return tag;
      })

      if(this._tag && b.tags.indexOf(this._tag) === -1) {
        return false;
      }

      return true;
    }).map((entry, index) => {
      return {
        id: index,
        image: entry.image,
        title: entry.title,
        tags: entry.tags,
        summary: entry.summary,
        author: entry.author,
        createdAt: entry.createdAt
      }
    });
  }

  renderTitle(params, caller) {
    const entry = Object.values(blog).find(b => b.title === params.value);

    return <div className="fullHeight">
      <div style={{        
        width: '100%',
        display: 'flex',
        marginTop: 15,
      }}>
        <div
          style={{
            width: 300,
            height: 'auto',
          }}
        >
          <img          
            alt={params.row.title} 
            src={params.row.image} 
            onLoad={()=>{
              caller.adjustHeight();
            }}
          />
        </div>
        <div 
          style={{
            flex: 1,
            marginLeft: 10,
            lineHeight: 'normal',
            whiteSpace: 'break-spaces',
          }}
        >
          <div style={{
            marginBottom: 10
          }}>
            <a style={{fontSize:20, textDecoration: 'none'}} href={`/blog/${entry.url}`}>{entry.title}</a>
          </div>
          {params.row.summary}
          <div style={{
            marginTop: 10,
            color: 'gray'
          }}>
            By - {params.row.author}, {params.row.createdAt}
          </div>
          <div className="readmore">
            <a href={`/blog/${entry.url}`}>Read More</a>
          </div>
        </div>
      </div>
    </div>
  }

  cellClicked = ()=> {}

  render() {

    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <SearchBarBasic 
            openSkillpage={true}
            hideHeader={true} 
            source={"blogs"}
          />
        </div>
        <div 
          style={{
            width: '100%', 
            marginTop: 50 ,
          }}
          className="bloggridrow"
        >        
          <div className="blogGridLeftCol">
            {
              this.renderGrid(
                this.state.gridData,
                this.cellClicked,
                [],
                {
                  pagination: true,
                  pageSize: 10,
                  headerHeight:0,
                  disableColumnResize: true,
                  gridToolbar: {
                    GridColumnsToolbarButton: false,
                    GridFilterToolbarButton: false,
                    GridDensitySelector: false,                
                    GridToolbarExport: false
                  }
                }
              )
            }
          </div>
          <div className="blogGridRightCol">
            {this.tagList.map((tag, index)=>{
              return (
                <div 
                  key={"tag-"+index}
                  style={{
                    padding: '10px 20px',
                    borderRadius: 10,
                    border: '1px solid orange',
                    margin: 10,
                    cursor: 'pointer',
                    backgroundColor: this._tag === tag? "orange" : "",
                    color: this._tag === tag? "white" : ""
                  }}
                  className="tagContainer"
                  onClick={()=>{
                    window.location=`/blog?tag=`+ tag;
                  }}
                >
                  {tag}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

}

export default CustomXGridTheme(BlogIndex);