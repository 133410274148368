import React from 'react';

import '../../Skill.css';

import skills from '../../skills.json';
import jobs from '../../jobs.json';

import User from '../../User';
import SkillBackground from '../../SkillBackground.js';
import SkillResources from '../../SkillResources.js';
import Header from '../../components/Header';
import RelatedSkills from '../../RelatedSkills.js';
import GoogleTrends from '../../components/GoogleTrends';
import convertArrayToObject from '../../utilities';
import categories from '../../skill-categories.json';


class Job extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.isSkillUp = window.location.hostname.includes('skillup');
  }

  componentDidMount() {    
    new User().load().then(user => {
      this.setState({user: user});
    });
  }


  //
  // Class methods

  updateUser = (user) => {
    this.setState({user: user});
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  getTopIntelligence(job, skillsMap) {
    if (!job) return ""

    const intelligenceMap = {}

    job.skills.forEach((s) => {
      const skill = skillsMap[s.id]
      if (skill && skill.hasOwnProperty('categories')) {
        skill.categories.filter((category) => Object.keys(categories).includes(category) && categories[category].isActive).forEach((category) => {
          if (intelligenceMap.hasOwnProperty(category))
            intelligenceMap[category]++
          else
            intelligenceMap[category] = 1
        })
      }
    })

    const sorted = [];
    for (var intelligence in intelligenceMap) {
        sorted.push([intelligence, intelligenceMap[intelligence]]);
    }

    sorted.sort((a, b) => b[1] - a[1])

    return sorted[0][0]
  }


  //
  // Rendering

  render() {
    var name = decodeURIComponent(this.props.match.params.job);

    const job = jobs[name];
    if (job)
      job.relatedSkills = job.skills.map(s => s.id);

    return (
      <div>
        <Header color='white' />
        <div className="content-wrapper">
          <SkillBackground skill={job} user={this.state.user} label={name} />
          
          <section className="page-section">
            {this.renderSummary(job)}<br />
            {this.renderTrends(name)}<br />
            {this.renderJobs(name)}<br />
            {
              job && job.hasOwnProperty('resources') && job.resources.length > 0 &&
                <SkillResources skill={job} />
            }
            <div className="dashboard-card">
              {
                job &&
                  <RelatedSkills skill={job} user={this.state.user} skills={skills} />
              }
            </div>
          </section>
        </div>
      </div>
    );
  }

  renderSummary(job) {
    if (!job) return ""

    const skillsMap = convertArrayToObject(skills, '_id');
    const firstSkillName = skillsMap[job.skills[0].id].name
    const topIntelligence = this.getTopIntelligence(job, skillsMap)

    return (
      <div className="dashboard-card">
        <p>
          The {job.name} job uses {job.skills.length} skills, including the <a href={`/skill/${firstSkillName}`}>{firstSkillName}</a> skill.
          The main intelligence it uses is <a href={`/intelligence/${topIntelligence}`}>{topIntelligence}</a>.
        </p>
      </div>
    )
  }

  renderTrends(name) {
    return (
      <div id="widget" className="dashboard-card" style={{paddingTop: '20px'}}>
        <h2 className="dashboard-title">Trends</h2>
        <GoogleTrends
          type="TIMESERIES"
          keyword={name}
          url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
        />
      </div>
    )
  }

  renderJobs(name) {
    return (
      <div className="dashboard-card">
        <h2 className="dashboard-title"><strong>{name}</strong> Jobs</h2>
        <ul>
          <li>
            <a href={`https://www.indeed.com/q-${name.toLowerCase().replaceAll(' ', '-')}-jobs.html`} target="_blank" rel="noopener noreferrer">Indeed.com</a>
          </li>  
          <li>
            <a href={`https://www.linkedin.com/jobs/search/?keywords=${encodeURIComponent(name.toLowerCase())}`} target="_blank" rel="noopener noreferrer">Linkedin Jobs</a>
          </li>
          <li>
            <a href={`https://www.ziprecruiter.com/candidate/search?search=${encodeURIComponent(name.toLowerCase())}`} target="_blank" rel="noopener noreferrer">Zip Recruiter</a>
          </li>  
        </ul>
      </div>
    )
  }

}

export default Job;