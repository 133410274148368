import React from 'react';
import BusinessGrid from './BusinessGrid';
import SearchBarBasic from '../../SearchBarBasic.js';
import CommitmentPieChart from './CommitmentPieChart';

class OpenPositionsTab extends React.Component {

  renderOpenPositionsGrid = () => {
    if (!this.props.getData()) return this.props.renderSkeleton();

    return (
      <BusinessGrid 
        jobs={this.props.getData()} 
        groupedSkills={this.props.getGroupedSkills()} 
        apiKey={this.props.apiKey} 
      />
    )
  }

  renderTop = (key, limit = 3) => {
    if (!this.props.getData()) return this.props.renderSkeleton();

    const ordered = this.props.ordered(key);

    return (
      <div className="dashboard-card" style={{float: 'left', width: "320px", marginLeft: '20px'}}>
        <h2 className="dashboard-title">{key.charAt(0).toUpperCase() + key.slice(1)}s</h2>
        <ol>
          {
            ordered && ordered.map((entry, index) => 
              index < limit && <li key={"entry"+index}>
                <span>{entry[0]}</span> 
                <span style={{float: 'right'}}>{entry[1]}</span>
              </li>
            )
          }
        </ol>
      </div>
    );
  }

  render() {
    return(
      <>
        <div>
          <div className="content-wrapper" style={{
            margin: '20px 0 20px 0'
          }}>
            <SearchBarBasic 
              openSkillpage={true}
              hideHeader={true} 
              source={"businessJobs"}
              getData={this.props.getData}
            />
          </div>
          <div style={{width: '80vw', margin: '0px auto'}}>
            <div className="dashboard-card" style={{width: '320px', float: 'left', }}>
              <CommitmentPieChart getData = {this.props.getData} />	
            </div>			
            {this.renderTop('department', this.props.apiKey ? 25 : 10)}
            {this.renderTop('location', this.props.apiKey ? 25 : 10)}
          </div>
          <div style={{clear: 'both'}} />
          <br /><br />
          <div className="dashboard-card" style={{margin: '0 5px 0 5px'}}>
            <h2 className="dashboard-title">All open positions</h2><br />
            {this.renderOpenPositionsGrid()}      
          </div>
        </div>
      </>
    )
  }
}

export default OpenPositionsTab;