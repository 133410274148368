import * as React from 'react';
import PieChart from "../PieChart"
const colors = ["#F88017", "#f24f13", "#EDC9AF", "#d99a6c", "#F3E5AB", "#dbb412"];

class CommitmentPieChart extends React.Component {

  render() {

    let commitments = {};
    let data = this.props.getData();
    let commitmentsPieData = [];
    let updateChart = false;
    if(data) {
      let totalJobs = data.length;
      updateChart = true;
      data.map( job => {
        if(!commitments[job.commitment]) {
          commitments[job.commitment] = 1
        } else {
          commitments[job.commitment] += 1
        }
        return job;
      })
            
      commitments = Object.entries(commitments)
        .sort(([,a],[,b]) => b-a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

      commitmentsPieData = Object.keys(commitments).map((commitment, index) => {
        let percentage = ((commitments[commitment] || 0 ) * 100) / totalJobs;

        return {
          percentage: Math.round(percentage),
          color: colors[index],
          label: commitment,
          link: `#`
        }
      })
    }

    if(!commitmentsPieData.length) return null;
    
    return (

      <div>
        <h2 className="dashboard-title">
            Commitment
        </h2>
        <PieChart 
          data={commitmentsPieData}
          updateChart={updateChart}
        />
      </div>

    )
  }

}

export default CommitmentPieChart;