import React from 'react';
import { withRouter } from 'react-router-dom'

import skills from '../../skills';

import '../../utilities';
import './ReflectionJournalEntry.css';

import Header from '../../components/Header';
import ReflectionJournalEntry from './ReflectionJournalEntry';
import convertArrayToObject from '../../utilities';
import User from '../../User';

const queryString = require('query-string');

class ReflectionJournal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entry: {}
    };
  }

  componentDidMount() {
    new User().load().then(user => {
      this.setState({user: user});
      
      const qs = queryString.parse(this.props.location.search);

      if (this.props.match.params.status === 'edit' && qs.date && qs.index) {
        const reflection = user.data.reflection;
        if (reflection && reflection[qs.date] && reflection[qs.date][qs.index]) {
          this.setState({entry: reflection[qs.date][qs.index]});
        }
      }
    });
  }

  saveUser() {
    this.state.user.saveLocal();
  }

  handleDeleteEntry = (entry) => {
    if (!window.confirm("Are you sure you want to delete this entry? This is a permanent change.")) return;

    const index = this.state.user.reflection[entry.date].findIndex(e => e.skillId === entry.skillId);
    this.state.user.reflection[entry.date].splice(index, 1);

    this.setState({user: this.state.user});
    this.saveUser();
  }

  handleAddEntry = () => {
    this.props.history.push('/me/reflection/new');
  }

  handleCancel = () => {
    window.history.back();
  }

  handleChangeEntry = (event) => {
    const entry = this.state.entry;
    entry[event.target.dataset.field] = event.target.value;
    this.setState({entry: entry});
  }

  handleSaveEntryClicked = () => {
    const date = (new Date()).toISOString().slice(0, 10);

    let reflection = this.state.user.data.reflection ? this.state.user.data.reflection : {};

    if (!reflection.hasOwnProperty(date))
      reflection[date] = [];

    const qs = queryString.parse(this.props.location.search);
    let entry = this.state.entry;
    entry.date = date;

    if (this.props.match.params.status === 'new' && qs.id)
      entry.skillId = qs.id; // TODO: Verify that it exists in our database

    if (!qs.index)
      reflection[date].push(entry);
    else
      reflection[date][qs.index] = entry;

    let user = this.state.user;
    user.data.reflection = reflection;
    
    this.setState({user: user, entry: entry});
    this.saveUser();
    this.props.history.push('/me/reflection');
  }


  render() {
    const grouped = convertArrayToObject(skills, '_id');
    const status = this.props.match.params.status;

    return (
      <div>
        <Header />
        {
          !status &&
            <div className="content-wrapper">
              <a href="/me">My profile</a>
              <h2>My Reflection Journal</h2>
              <button onClick={this.handleAddEntry.bind(this)}>Add entry</button>
              <hr />
              {
                this.state.user && this.state.user.data.reflection && Object.keys(this.state.user.data.reflection).map(key =>
                  <div key={key}>
                    <h3 id={key}>{key}</h3>
                    {
                      this.state.user.data.reflection[key].map((entry, index) =>
                        <ReflectionJournalEntry key={index} user={this.state.user} skills={grouped} entry={entry} index={index} handleDeleteEntry={this.handleDeleteEntry} />
                      )
                    }
                  </div>  
                )
              }
            </div>
        }
        {
          (status === 'new' || status === 'edit') &&
          <div className="content-wrapper">
            <h2>Reflection Journal Entry</h2>
            <h4>What went right?</h4>
            <textarea value={this.state.entry.right} onChange={this.handleChangeEntry.bind(this)} data-field="right" />
            <h4>What went wrong?</h4>
            <textarea value={this.state.entry.wrong} onChange={this.handleChangeEntry.bind(this)} data-field="wrong" />
            <h4>How can I improve?</h4>
            <textarea value={this.state.entry.improve} onChange={this.handleChangeEntry.bind(this)} data-field="improve" />
            <h4>What lessons have I learned?</h4>
            <textarea value={this.state.entry.lessons} onChange={this.handleChangeEntry.bind(this)} data-field="lessons" />
            <h4>What would I do differently next time?</h4>
            <textarea value={this.state.entry.different} onChange={this.handleChangeEntry.bind(this)} data-field="different" />
            <h4>What should I do next?</h4>
            <textarea value={this.state.entry.next} onChange={this.handleChangeEntry.bind(this)} data-field="next" />
            <h4>Notes</h4>
            <textarea value={this.state.entry.notes} onChange={this.handleChangeEntry.bind(this)} data-field="notes" />
            <br /><br />
            <div>
              <button className="bg-red" style={{padding: '20px 30px 20px 30px'}} onClick={this.handleCancel.bind(this)}>Cancel</button>&nbsp;
              <button className='bg-orange' onClick={this.handleSaveEntryClicked.bind(this)}>Save</button>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(ReflectionJournal);