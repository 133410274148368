import React from 'react';
import './Header.css';

import HeaderNavigation from './HeaderNavigation';

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      color: this.props.color || 'black'
    };
  }

  getLogo() {
    return (window.location.hostname.includes('skillup')) ? '/logo.png' : '/logo-skillsdb.png';
  }

  setColor(color) {
    this.setState({color: color});
  }

  render() {
    return (
      <div className="Header">
        {
          !this.props.hideLogo &&
            <div className="header-logo">
              <a href={process.env.PUBLIC_URL + '/'}>
                <img className="logo" alt="Skills DB" src={process.env.PUBLIC_URL + this.getLogo()} />
              </a>
            </div>
        }
        <HeaderNavigation color={this.state.color}/>
      </div>
    );
  }
}

export default Header;