import React from 'react';

import Background from './components/Background';
import Main from './Main.js';
import Header from './components/Header';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.isSkillUp = window.location.hostname.includes('skillup');

  }
  
  componentDidMount() {
    document.title = "Learn any skill on " + this.isSkillUp ? "SkillUp Academy" : "Skills DB";
  }

  getColor() {
    return this.isSkillUp ? 'white' : '';
  }

  hideLogo() {
    return this.isSkillUp ? false : true;
  }

  render() {
    return (
      <div>
        <Header color={this.getColor()} hideLogo={this.hideLogo()} />
        <Background />
        <Main />
      </div>
    );
  }
}

export default Home;