import React from 'react';
import { withRouter } from 'react-router-dom'
import './utilities';
import convertArrayToObject from './utilities';
import SkillsGrid from './SkillsGrid.js';
import categories from './skill-categories.json';

class RelatedSkills extends React.Component {
  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  labelClicked(skill) {
    this.props.history.push(`/skill/${encodeURIComponent(skill.name)}`);
    this.props.history.go(0); // Doing that only because the Google Trends widget doesn't update.
  }

  getSkillsForPurpose(groupedSkills, purpose) {
    if (!this.props.skill.hasOwnProperty('relatedSkills'))
      return 0

    let professionalSkills = []

    this.props.skill.relatedSkills.forEach((rs) => {
      let skill = null
      if (typeof rs === 'string')
        skill = groupedSkills[rs]
      else if (typeof rs === 'object')
        skill = groupedSkills[rs.id]

      if (skill && skill.hasOwnProperty('purpose') && skill.purpose.includes(purpose))
        professionalSkills.push(skill)
    })

    return professionalSkills
  }

  getTopIntelligences(groupedSkills) {
    if (!this.props.skill.hasOwnProperty('relatedSkills'))
      return 0

    const intelligenceMap = {}

    this.props.skill.relatedSkills.forEach((s) => {
      let skill = null
      if (typeof s === 'string')
        skill = groupedSkills[s]
      else if (typeof s === 'object')
        skill = groupedSkills[s.id]

      if (skill && skill.hasOwnProperty('categories')) {
        skill.categories.filter((category) => Object.keys(categories).includes(category) && categories[category].isActive).forEach((category) => {
          if (intelligenceMap.hasOwnProperty(category))
            intelligenceMap[category]++
          else
            intelligenceMap[category] = 1
        })
      }
    })

    const sorted = [];
    for (var intelligence in intelligenceMap) {
        sorted.push([intelligence, intelligenceMap[intelligence]]);
    }

    sorted.sort((a, b) => b[1] - a[1])

    return sorted
  }

  getCombinedNumberResources(groupedSkills) {
    if (!this.props.skill.hasOwnProperty('relatedSkills'))
      return 0

    let numResources = 0

    this.props.skill.relatedSkills.forEach((rs) => {
      let skill = null
      if (typeof rs === 'string')
        skill = groupedSkills[rs]
      else if (typeof rs === 'object')
        skill = groupedSkills[rs.id]

      numResources += skill && skill.hasOwnProperty('resources') ? skill.resources.length : 0
    })

    return numResources
  }

  groupedRelatedSkills() {
    const group = {}
    const DEFAULT_TYPE = 'adjacent'

    this.props.skill.relatedSkills.forEach((skill) => {
      if (typeof skill === 'string')
        skill = {id: skill, type: DEFAULT_TYPE}

      if (!skill.hasOwnProperty('type'))
        skill.type = DEFAULT_TYPE

      if (group.hasOwnProperty(skill.type))
        group[skill.type].push(skill.id)
      else
        group[skill.type] = [skill.id]
    })
    
    return group
  }


  //
  // RENDER

  render() {
    if (!this.props.skill.hasOwnProperty('relatedSkills') || this.props.skill.relatedSkills.length === 0) 
      return "";
    
    const grouped = convertArrayToObject(this.props.skills, '_id');
    const groupedRelatedSkills = this.groupedRelatedSkills()

    return (
      <div>
        <h2 className="dashboard-title"><strong>{this.props.skill.name}</strong> Related Skills</h2>
        {this.renderSummary(grouped)}
        {
          Object.keys(groupedRelatedSkills).map((key) => {
            return (
              <div key={key} className="dashboard-card" style={{marginTop: '40px'}}>
                <h2 className="dashboard-title">{this.titleCase(key)}</h2>
                <SkillsGrid skills={groupedRelatedSkills[key].map((skillId) => grouped[skillId])} />
              </div>
            )
          })
        }
        <br /><br /><br />
      </div>
    )
  }

  renderSummary(groupedSkills) {
    if (this.props.skill.relatedSkills.length === 0) return ""

    const professionalSkills = this.getSkillsForPurpose(groupedSkills, 'professional')
    const personalSkills = this.getSkillsForPurpose(groupedSkills, 'personal')
    const topIntelligences = this.getTopIntelligences(groupedSkills)

    const topOneIntelligences = [topIntelligences[0]]
    for (let i = 1; i < topIntelligences.length; i++) {
      if (topIntelligences[i][1] === topOneIntelligences[0][1])
        topOneIntelligences.push(topIntelligences[i])
    }

    return (
      <p>
        {professionalSkills.length > 0 &&
          <>
            {this.props.skill.name} is related to {professionalSkills.length} professional skills, including <a href={`/skill/${professionalSkills[0].name}`}>{professionalSkills[0].name}</a>.&nbsp;

            {personalSkills.length > 0 &&
              <>It's also related to {personalSkills.length} personal skills. </>
            }
          </>
        }
        {professionalSkills.length === 0 && personalSkills.length > 0 &&
          <>{this.props.skill.name} is related to {personalSkills.length} personal skills, including <a href={`/skill/${personalSkills[0].name}`}>{personalSkills[0].name}</a>.&nbsp;</>
        }
        {topOneIntelligences.length === 1 &&
          <>The main intelligence of its related skills is {topOneIntelligences[0][0]}. </>
        }
        {topOneIntelligences.length > 1 &&
          <>The main intelligences of its related skills are {topOneIntelligences.map((intelligence) => intelligence[0]).join(' & ')}. </>
        }
        <>We have a combined {this.getCombinedNumberResources(groupedSkills)} resources for its related skills.</>
      </p>
    )
  }
}

export default withRouter(RelatedSkills);