import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import { init } from 'emailjs-com';
init('user_uzT8w9pE6BO6OCLfzSSAv');


const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = () => {
        if (name && email && message) {
            const serviceId = 'service_9ut3oa9';
            const templateId = 'template_gl7yksi';
            const templateParams = {
                name,
                email,
                message
            };

            emailjs.send(serviceId, templateId, templateParams)
                .then(response => console.log(response))
                .then(error => console.log(error));
    
            setName('');
            setEmail('');
            setMessage('');
            setEmailSent(true);
        } else {
            alert('Please fill in all fields.');
        }
    }

    return (
        <table style={{width: "300px", margin: "0px auto"}}>
            <tbody>
                <tr>
                    <td>Name: </td>
                    <td><input className="margin5 padding5" type="text" placeholder="Your Name" value={name} onChange={e => setName(e.target.value)} /></td>
                </tr>
                <tr>
                    <td>Email Address: </td>
                    <td><input className="margin5 padding5" type="email" placeholder="Your email address" value={email} onChange={e => setEmail(e.target.value)} /></td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <textarea style={{width: "100%"}} placeholder="Your message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <button onClick={submit}>Send Message</button>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        {emailSent &&
                            <span>Thank you for your message, we will be in touch as soon as possible!</span>
                        }
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default ContactForm