import { withStyles } from "@material-ui/core";

const CustomTheme = withStyles({
  theme: {
    "& .MuiDataGrid-viewport" : {
    	// TODO: set/reset by dynamically.. need to find a way ??
      maxHeight: "99999px !important"
    },
    "& .MuiDataGrid-renderingZone" : {
      maxHeight: "99999px !important"
    },
    "& .MuiDataGrid-row": {
			maxHeight: "9999px !important"
		},
		"& .MuiDataGrid-cell": {
			maxHeight: "9999px !important"
		}
  }
});

export default CustomTheme;