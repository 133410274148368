import React from 'react';
import axios from 'axios';
import GoogleTrends from '../GoogleTrends';
import DatePickerFn from '../DatePicker';
import Paper from '@material-ui/core/Paper';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import skills from '../../skills.json';
import convertArrayToObject from '../../utilities';

const NETVIEWER_API_URL = process.env.NODE_ENV === 'development' ? 'https://93l3o3musg.execute-api.us-east-1.amazonaws.com/dev' : 'https://l7st1kuto2.execute-api.us-east-1.amazonaws.com/prod';


class BusinessDataTab extends React.Component {

  constructor(props) {
    super(props)

    const today = new Date();
    today.setHours(0, 0, 0, 0)
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

    this.state = {
      fromDate: lastWeek,
      toDate: today,
      dates: {}
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  handleFromDateChanged = (date = new Date()) => {
    date.setHours(0, 0, 0, 0)
    this.setState({
      fromDate: date
    })
  }

  handleToDateChanged = (date = new Date()) => {
    date.setHours(0, 0, 0, 0)
    this.setState({
      toDate: date
    })

    this.refreshData()
  }

  lastWeek = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0)
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  }

  treatAsUTC(date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

  daysBetween(startDate, endDate) {
      var millisecondsPerDay = 24 * 60 * 60 * 1000;
      return (this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerDay;
  }

  refreshData = () => {
    this.setState({data: {}})

    for (let i = 0; i < this.daysBetween(this.state.fromDate, this.state.toDate); i++) {
      const date = new Date(this.state.fromDate.getTime())
      date.setDate(date.getDate() + i)
      
      this.fetchDataFromServer(date)
    }
  }

  fetchDataFromServer = (date = new Date()) => {
    let formattedDate = null
    if (date) {
      date.setHours(0, 0, 0, 0)
      formattedDate = date.toISOString().split('T')[0]
    }

    const business = this.props.business.toLowerCase()
    const apiUrl = date ? `${NETVIEWER_API_URL}/business/${business}/date/${formattedDate}` : `${NETVIEWER_API_URL}/business/${business}`;

    try {
      axios.get(apiUrl, {business: business}).then((response) => {
        const responseData = response.data.data;
  
        const stateData = this.state.data
        stateData[formattedDate] = responseData.jobs
        this.setState({data: stateData})
      })
    } catch (err) {
      // date is probably missing
    }
  }

  orderData = () => {
    if (!this.state.data) return []

    const arr = Object.keys(this.state.data).map((key) => {
      return {date: new Date(key), data: this.state.data[key]}
    })

    arr.sort((a, b) => a.date - b.date)

    return arr
  }

  toBase64 = (obj = {}) => {
    return Buffer.from(JSON.stringify(obj)).toString('base64')
  }

  filledPositions = (orderedData = []) => {
    if (orderedData.length === 0) return []// EXIT

    let previous = orderedData[orderedData.length - 1]
    const filledPositions = []
    for (let i = (orderedData.length - 1); i > 0; i--) {
      if (this.toBase64(orderedData[i].data) === this.toBase64(previous.data))
        continue

      const diff = orderedData[i].data.filter(({ url: id1 }) => !previous.data.some(({ url: id2 }) => id2 === id1));
      diff.forEach((position) => filledPositions.push(position))

      previous = orderedData[i]
    }

    return filledPositions
  }

  newPositions = (orderedData = []) => {
    if (orderedData.length === 0) return [] // EXIT

    let previous = orderedData[0]
    const newPositions = []
    for (let i = 1; i < orderedData.length; i++) {
      if (this.toBase64(orderedData[i].data) === this.toBase64(previous.data))
        continue

      const diff = orderedData[i].data.filter(({ url: id1 }) => !previous.data.some(({ url: id2 }) => id2 === id1));
      diff.forEach((position) => newPositions.push(position))

      previous = orderedData[i]
    }

    return newPositions
  }



	render() {
    const orderedData = this.orderData()
    const filledPositions = this.filledPositions(orderedData)
    const newPositions = this.newPositions(orderedData)
    const groupedSkills = convertArrayToObject(skills, '_id');

		return(
      <div className="content-wrapper" style={{paddingTop: "20px"}}>
        <div className="dashboard-card">
          <h2 className="dashboard-title">Changes</h2>
          <div>
            <span>From: </span>
            <div style={{display: 'inline-block'}}>
              <DatePickerFn 
                selected={this.state.fromDate}
                handleDateChanged={this.handleFromDateChanged.bind(this)} />
            </div>
            <span> to </span>
            <div style={{display: 'inline-block'}}>
              <DatePickerFn 
                selected={this.state.toDate}
                handleDateChanged={this.handleToDateChanged.bind(this)} />
            </div>
          </div>
          {this.renderAvailablePositions(orderedData)}
          {this.renderFilledPositions(filledPositions)}
          {this.renderAcquiredSkills(filledPositions, groupedSkills)}
          {this.renderNewPositions(newPositions)}
          {this.renderSkillsLookingFor(newPositions, groupedSkills)}
        </div>
        <div id="widget" style={{maxWidth: '600px', paddingTop: '20px', margin: 'auto'}}>
          <GoogleTrends
            type="TIMESERIES"
            keyword={this.props.business}
            url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
          />
        </div>
      </div>
		)
	}

  renderAvailablePositions = (orderedData) => {
    if (orderedData.length === 0) return // EXIT

    return (
      <div>
        <h4>Available Positions</h4>
        <Paper>
          <Chart
            data={orderedData.map((obj) => {
              return {date: obj.date.toISOString().substring(0, 10), num: obj.data.length}
            })}
          >
            <ArgumentAxis />
            <ValueAxis />

            <LineSeries valueField="num" argumentField="date" />
          </Chart>
        </Paper>
      </div>
    )
  }

  renderFilledPositions = (filledPositions) => {
    return (
      <div>
        <h4>Filled Positions ({filledPositions.length})</h4>
        <ul>
          {
            filledPositions.map((obj) => {
              return <li key={obj.url}>{obj.name}</li>
            })
          }
        </ul>
      </div>
    )
  }

  renderAcquiredSkills = (filledPositions, groupedSkills) => {
    let acquiredSkills = []
    filledPositions.forEach((position) => {
      acquiredSkills = acquiredSkills.concat(
        position.skills.filter((skillId) => !acquiredSkills.includes(skillId))
      )
    })

    return (
      <div>
        <h4>Acquired Skills ({acquiredSkills.length})</h4>
        <ul>
          {
            acquiredSkills.map((skillId) => {
              return <li key={skillId}>{groupedSkills[skillId].name}</li>
            })
          }
        </ul>
      </div>
    )
  }

  renderNewPositions = (newPositions) => {
    if (newPositions.length === 0) return // EXIT

    return (
      <div>
        <h4>New Positions ({newPositions.length})</h4>
        <ul>
          {
            newPositions.map((obj) => {
              return <li key={obj.url}>{obj.name}</li>
            })
          }
        </ul>
      </div>
    )
  }

  renderSkillsLookingFor = (newPositions, groupedSkills) => {
    let skillsLookingFor = []
    newPositions.forEach((position) => {
      skillsLookingFor = skillsLookingFor.concat(
        position.skills.filter((skillId) => !skillsLookingFor.includes(skillId))
      )
    })

    return (
      <div>
        <h4>Skills Looking to Acquire ({skillsLookingFor.length})</h4>
        <ul>
          {
            skillsLookingFor.map((skillId) => {
              return <li key={skillId}>{groupedSkills[skillId].name}</li>
            })
          }
        </ul>
      </div>
    )
  }
}

export default BusinessDataTab;