import React from 'react';
import './SearchBar.css';
import SearchBarContainer from './components/SearchBar/Container';
// import Procificency from './Proficiency'; Removing for basic version of the search bar
// import User from './User';

class SearchBar extends SearchBarContainer {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      search: "",
      user: props.user ? props.user : {},
      selectionIndex: 0,
      data: [],
      showModal: false,
    };
  }

  getStateData = () => {
    return this.state.data;
  }

  resetData = () => {
    this.setState(
      prevState => {
        return {
          search: "",
          data: [],
          selectionIndex: 0
        }
      }
    )
  }

  componentDidMount() {
    super.componentDidMount()
    // new User().load().then(user => {
    //   this.setState({user: user});
    // })
    
  }

  handleClose = () => {
    this.setState(
      prevState => {
        return {
            search: "",
            data: [],
            selectionIndex: 0
        }
      }
    )
  }

  getPlaceHoderText = ( source ) => {

    let placeholder = null;
    
    switch(source) {
      case "jobs":
        placeholder = "Search Jobs";
        break;
      case "intelligences":
        placeholder = "Search Intelligences";
        break;
      case "tags":
        placeholder = "Search Tags";
        break;
      case "blogs":
        placeholder = "Search Blogs";
        break
      case "businessSkills":
        placeholder = "Search Skills";
        break;
      case "businessJobs":
        placeholder = "Search Jobs";
        break;  
      default:
        placeholder = "Search Skills";
    }

    return placeholder;
  }

  handleChange = (event) => {
    
    if (event.target.value === "") {
      this.handleClose();
    }

    this.setState({
      search: event.target.value,
      selectionIndex: 0
    },()=>{
        let data  = this.getResult(this.state.search);
        this.setState({
          data: data || [],
          showModal: data && data.length ? true : false
        })        
    });

  }

  onKeyDown = (e) => {

    let linkRef = this.getLinksRef();

    if (e.keyCode === 13) {

      if(this.props.openSkillpage) {
        linkRef[this.state.selectionIndex].click();
      } else {
        this.setState({
          selectionIndex: 0,
          showModal: false,
          search: this.state.data[this.state.selectionIndex].name
        });
      }

    } else if (e.keyCode === 38) {

      if (this.state.selectionIndex === 0) {
        return;
      }
      this.setState({ selectionIndex: this.state.selectionIndex - 1 }, ()=> {
        this.scrollSelectedIndex();
      });
      
    } else if (e.keyCode === 40) {
      
      if (this.state.selectionIndex  === this.state.data.length - 1) {
        return;
      }
      
      this.setState({ selectionIndex: this.state.selectionIndex + 1 }, ()=> {        
        this.scrollSelectedIndex();
      });
    }

  }

  scrollSelectedIndex = ()=> {
    let linkRef = this.getLinksRef();
    if(this.state.selectionIndex) {
      linkRef[this.state.selectionIndex] && linkRef[this.state.selectionIndex].focus();
      this.searchInputRef.focus();
    }      
    
  }

  getSelectionIndex = () => {
    return this.state.selectionIndex;
  }

  render() {
    const placeholder = this.getPlaceHoderText(this.props.source);
    
    return (
      <div>
        {
          !this.props.hideHeader && 
            <h1 style={{color: this.props.theme ? this.props.theme : 'black'}}>I want to learn...</h1>
        }
        
        <div className="border rounded inline">
          <div style={{margin: 'auto 0'}}>🔍</div>
          <input 
            type="text" 
            className={`search-input no-border ${this.props.theme === "white" ? "transparent" : ""} ${this.props.theme}`} 
            onChange={this.handleChange} 
            onKeyDown={this.onKeyDown}
            value={this.state.search} 
            placeholder={placeholder}
            ref={ x => this.searchInputRef = x }
          />
          <div className={`close ${this.state.search !== "" && "active"}`} onClick={this.handleClose}>&times;</div >
        </div>
        <div>
        {
        this.state.showModal && this.state.search !== '' &&
          <div ref={this.modalRef} className={`modal ${this.state.search !== "" && "active"}`}>
            <div className="modal-content">
              <ul>
                {
                  this.state.data && this.renderModal()
                }
              </ul>
            </div>

          </div>
        }
        </div>
      </div>
    )
  }
}

export default SearchBar;