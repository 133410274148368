import * as React from 'react';
import CustomXGridContainer from '../CustomXGridContainer';
import CustomXGridTheme from '../CustomXGridContainer/Theme';

class BusinessGrid extends CustomXGridContainer {
  constructor(props) {
    super(props);
    
    this.state = {
      width: window.innerWidth,
      filterModel: [],
      jobs: props.jobs,
      gridData: {
        columns: [],
        rows: []
      }
    };
  }  
  
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);
    const data = this.getData();
    this.setState({
      gridData: data
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  
  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }
  
  getData() {
    return {
      columns: [
        {field: 'id', headerName: 'Id', hide: true},
        {field: 'job', headerName: 'Job', width: 250, renderCell: (params) => <div className="fullHeight"><a href={params.value.url} target="job">{params.value.name}</a></div>},
        {field: 'department', headerName: 'Department', width: 200},
        {field: 'location', headerName: 'Location', width: 200},
        {field: 'skills', headerName: "Required Skills", flex: 1, width: 200, renderCell: this.renderSkills}
      ],
      rows: this.getRows()
    }
  }
  
  getRows() {    
    return Object.values(this.state.jobs).sort((a, b) => a.name.localeCompare(b.name)).map((job, index) => {

      return {
        id: index,
        job: job,
        department: job.department.replace("Department ", ""),
        location: job.location.replace("Location ", ""),
        skills: this.getSkills(job.skills).join(', ')
      };
    });
  }  

  getSkills(jobSkills) {
    return jobSkills.filter(js => this.props.groupedSkills.hasOwnProperty(js)).map(js => this.props.groupedSkills[js].name);
  }

  cellClicked = ()=> {}

  
  //
  // RENDER
  
  render() {
    return (
      <div style={{width: '100%' }}>
        {
          this.renderGrid(
            this.state.gridData, 
            this.cellClicked, 
            this.state.filterModel, 
            {
              pagination: true,
              pageSize: !this.props.apiKey ? 10 : 50,
              // autoPageSize=!this.props.isLoggedIn
              gridToolbar: {
                GridColumnsToolbarButton: true,
                GridFilterToolbarButton: {
                  loginRequired: this.props.apiKey
                },
                GridDensitySelector: true,                
                GridToolbarExport: {
                  loginRequired: this.props.apiKey
                }
              }
            }
          )
        }
      </div>
    );
  }

  renderSkills(params) {
    return <div className="fullHeight">
      {
        params.value.split(', ').map((skillName,index) =>
          <div style={{
              float: 'left'
            }}
            key={"skillcontainer"+index}
          >
            <a href={`/skill/${skillName}`}>{skillName}</a>&nbsp; &nbsp;
          </div>
        )
      }
    </div>
  }
  
}

export default CustomXGridTheme(BusinessGrid);