import React from 'react';
import ExploreSourceSelector from './ExploreSourceSelector';
import Header from './components/Header';
import JobsGrid from './JobsGrid';
import SearchBar from './SearchBarBasic';
import SkillsGrid from './SkillsGrid';
import categories from './skill-categories.json';
import TagsGrid from './TagsGrid';
import CategoriesGrid from './CategoriesGrid';

const queryString = require('query-string');


class Explore extends React.Component {  

  constructor(props) {
    super(props);

    const qs = queryString.parse(this.props.location.search);
    
    this.state = {
      source: qs.source ? qs.source : 'skills',
      category: qs.category? qs.category : '',
      favorites: qs.favorites? qs.favorites : '',
      proficiencies: qs.proficiencies? qs.proficiencies : ''
    };
  }

  onFilterUpdated(filter) {
    this.setState({source: filter})
    this.searchBarRef.resetData();
  }

  //
  // RENDER

  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <SearchBar 
            openSkillpage={true} 
            hideHeader={true} 
            ref={ x => this.searchBarRef = x }
            source={this.state.source}            
          />
          <br />
          <center>
            <ExploreSourceSelector 
              source={this.state.source} 
              onFilterUpdated={this.onFilterUpdated.bind(this)} 
            />
          </center><br />
        </div>
        {this.state.source === 'skills'        && <SkillsGrid proficiencies={this.state.proficiencies} favorites={this.state.favorites} category={this.state.category} />}
        {this.state.source === 'jobs'          && <JobsGrid />}
        {this.state.source === 'intelligences' && <CategoriesGrid />}
        {this.state.source === 'tags'          && <TagsGrid />}
        {this.renderLegend()}
      </div>
    );
  }

  renderLegend() {
    return (
      <div className="content-wrapper">
        <h4>Legend</h4>
        <ul style={{listStyle: 'none'}}>
          <li>👔 = Professional</li>
          <li>🧑 = Personal</li>
          <li>&nbsp;</li>
          {
            Object.values(categories).filter(c => c.isActive).map((c, index) => 
              <li>
                <img 
                  key={c.name}
                  data-index={index}
                  src={`${process.env['PUBLIC_URL']}/${c.name.replace('/', '-')}@72x.png`}
                  alt={c.name}
                  width='24'
                  />
                <span> {c.name}</span>
              </li>
            )
          }
        </ul>
      </div>
    );
  }
}

export default Explore;