import SkillDbCollections from '../../SkillDbCollections'
const { jobs } = SkillDbCollections;

class JobSearch {

  constructor() {
    this.loadData();
  }

  loadData = ( ) => {         
    let data = []
    for(let i in jobs) {    
      data.push({
        id: jobs[i].name,
        name: jobs[i].name
      })
    }
    this.jobs = data.sort((a, b) => a.name.localeCompare(b.name));  
  }

  getResult = (searchTerm) => {
    let data = this.jobs && this.jobs.filter(
      job => job.name.toLowerCase().includes( searchTerm.toLowerCase() )
    );
    
    return data;
  }

  searchAnchorrefs = []

  goToJob = (job) => {
    window.location.href = "/job/" + job.name;
  }

  getModal = (props) => {
 
    let {selectionIndex,index, s} = props

    return ( 
      <li                           
        className="pointer"
        style={{
          backgroundColor: index === selectionIndex ? "#EBEDEF": "white"
        }} 
        onClick={ this.goToJob && this.goToJob.bind(this, s)}
        key={s._id}
      >        
        <a className="orangeColor"
          ref={ x => this.searchAnchorrefs[index] = x }  
          href={'/job/' + s.name}
        >
          {s.name}
        </a>        
      </li> 
    );
  }

}

export default JobSearch;