import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import './Proficiency.css';

import proficiency0 from './img/proficiency/0.png';
import proficiency1 from './img/proficiency/1.png';
import proficiency2 from './img/proficiency/2.png';
import proficiency3 from './img/proficiency/3.png';
import proficiency4 from './img/proficiency/4.png';

class Proficiency extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      level: 0,
      levels: {
        0: {
          'name': 'No proficiency',
          'image': proficiency0
        },
        1: {
          'name': 'Beginner',
          'image': proficiency1
        },
        2: {
          'name': 'Intermediate',
          'image': proficiency2
        },
        3: {
          'name': 'Expert',
          'image': proficiency3
        },
        4: {
          'name': 'Master',
          'image': proficiency4
        }
      }
    };
  }

  getLevel() {
    if (this.props.level !== undefined) return this.props.level;
    if (!this.props.user.data.mastery) return 0;
    if (!this.props.user.data.mastery.items.length === 0) return 0;

    const selected = this.props.user.data.mastery.items.find(i => i.skillId === this.props.skillId);
    
    return selected ? selected.proficiency : 0;
  }

  handleClick = () => {
    if (!this.props.user || this.props.readOnly) return;

    let level = this.getLevel();

    level++;

    if (level > 4) level = 0;

    this.props.user.updateMastery(this.props.skillId, level).then(response => {
      this.forceUpdate();
    });
  }

  render() {
    if (this.props.level === undefined) {
      if (!this.props.user) return <Skeleton />
      if (!this.props.user.data) return <Skeleton />
      if (!this.props.user.data.mastery) return <Skeleton />
    }

    const level = this.getLevel();

    return (
      <span 
        className="proficiency-container tooltip fullHeight" 
        onClick={this.handleClick.bind(this)}
        style={{
          margin: '0px'
        }}
      >
        <img alt={level} className={`dot bottom-down ${this.props.user && !this.props.readOnly ? 'pointer' : ''}`} src={this.state.levels[level].image} />
        <span className={!this.props.noTooltip ? "tooltiptext-top" : ""}>{this.state.levels[level].name}&nbsp;</span>
      </span>
    )
  }
}

export default Proficiency;