import React from 'react';
import {
  XGrid,
  GridToolbarContainer,
  GridColumnsToolbarButton,
  GridFilterToolbarButton,
  GridDensitySelector,
  GridToolbarExport
} from '@material-ui/x-grid';
import Sortable from 'sortablejs';
const filterSvgIcon = '<path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>';
const colSelectorIcon = `<path d="M6 5H3c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm14 0h-3c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-7 0h-3c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1z"></path>`
const headerHeight = 56;
class CustomXGridContainer extends React.Component {

  lazyLoad = (callback) => {
    setTimeout(callback, 100);
  }

  setTheme = () => {
    const { classes } = this.props;
    if(classes) {
      this.setState({
        classes: classes
      })
    }
  }

  renderGrid = (data, cellClicked, filterModel, options) => {
    this.gridOptions = options;
    let className = (this.state.classes && this.state.classes.theme) || '';

    return <XGrid
      {...data}
      {...options}
      loading={data.rows && data.rows.length === 0}
      rowHeight={38}
      headerHeight={headerHeight}
      autoHeight={true}
      onCellClick={cellClicked.bind(this)}
      components={{
        Toolbar: this.customToolbar.bind(this, options?(options.gridToolbar || false) : false),
      }}
      filterModel={{
        items: filterModel,
      }}
      onPageChange={this.adjustHeight}
      className={className}
    />
  }

  customToolbar = ( options ) => {

    if(options) {
      return (
        <GridToolbarContainer>
          {options.GridColumnsToolbarButton && options.GridColumnsToolbarButton.loginRequired?(
            <GridColumnsToolbarButton />
          ) : options.GridColumnsToolbarButton === true? <GridColumnsToolbarButton /> : null}
          {options.GridFilterToolbarButton && options.GridFilterToolbarButton.loginRequired?(
            <GridFilterToolbarButton />
          ) : options.GridFilterToolbarButton === true? <GridFilterToolbarButton /> : null}
          {options.GridDensitySelector && options.GridDensitySelector.loginRequired?(
            <GridDensitySelector />
          ) : options.GridDensitySelector === true? <GridDensitySelector /> : null}
          {options.GridToolbarExport && options.GridToolbarExport.loginRequired?(
            <GridToolbarExport />
          ) : options.GridToolbarExport === true? <GridToolbarExport /> : null}
        </GridToolbarContainer>
      );  
    }

    // default
    return (
      <GridToolbarContainer>
        <GridColumnsToolbarButton />
        <GridFilterToolbarButton />
        <GridDensitySelector />
      </GridToolbarContainer>
    );
  }

  componentDidMount( ) {
    document.addEventListener('mousedown', this.detectFilterHeader);             
    if(this.constructor.name === "CategoriesGrid") {
      // Applied after some time for TagsGrid, Otherwise horizontal scroll came..
      this.lazyLoad(this.setTheme);
    } else {
      this.setTheme();
    }
    this.adjustHeight();
  }

  adjustHeight = () => {
    setTimeout(()=>{
      let gridWindowContainer = document.getElementsByClassName("MuiDataGrid-windowContainer");
      let muiDataGridDataContainer = document.getElementsByClassName("MuiDataGrid-dataContainer");

      for (var i = 0; i < gridWindowContainer.length; i++)
        gridWindowContainer[i].style.height = (muiDataGridDataContainer[i].offsetHeight + headerHeight ) + "px";
    }, 200)
  }
  
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.detectFilterHeader);
  }

  detectFilterHeader = (event) => {
    let node = event.target, child;
    let getDescendants = (node) => {
      // console.log(node.outerHTML)
      if(node.nodeName.toLowerCase() === "path") {
        // hard coded to detect filter svg icon
        if(node.outerHTML === filterSvgIcon) {
          this.freezeScroll();
        }
        if(node.outerHTML === colSelectorIcon) {
          this.enableColSelectorDrag();
        }
      }
      
      for (var i = 0; i < node.childNodes.length; i++) {
        child = node.childNodes[i];
        getDescendants(child);
        if(child.nodeValue && child.nodeValue.toLowerCase() === "filters") {
          this.freezeScroll();                
        }
      }

      // Enable Column Selector Drag
      for (i = 0; i < node.childNodes.length; i++) {
        child = node.childNodes[i];
        getDescendants(child);
        if(child.nodeValue && child.nodeValue.toLowerCase() === "columns") {
          this.enableColSelectorDrag();
        }
      }
    }

    if(
      node.className === "MuiButton-label" ||
      node.nodeName.toLowerCase() === "path"
    ) {
      getDescendants(node);
    }
  }

  enableColSelectorDrag = () => {
    let self = this;
    var el = document.getElementsByClassName('MuiDataGridColumnsPanel-container');
    if(el) {
      setTimeout(()=>{
        Sortable.create(el[0], {
          handle: ".MuiIconButton-label",
          animation: 150,
          onEnd: function (/**Event*/evt) {
            let childs = evt.item && evt.item.parentNode.children;
            childs = [].slice.call(childs);
            let columns = childs.map(child=>{
              return child.innerText;
            })
            if(columns.length) {
              self.updateColumnOrder && self.updateColumnOrder(columns);
            }
          }
        });
      }, 200) 
    }
  }

  freezeScroll = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      let filtersEnter = true;
      window.onscroll = () => {
          if(filtersEnter)
            window.scrollTo(scrollLeft, scrollTop);
          filtersEnter = false;
          // remove listener.. no need for 2nd time
          document.removeEventListener('mousedown', this.detectFilterHeader);
      };
  }  

}

export default CustomXGridContainer;