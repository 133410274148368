import React from 'react';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';

import User from '../../User';
import Header from '../../components/Header';

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    new User().load().then(user => {
      this.setState({user: user});
    });
  }
  
  render() {
    return (
      <div>
        <Header />
        <div style={{position: 'relative', top: '-100px'}}>
          <AmplifyAuthenticator />
        </div>
      </div>
    );
  }
}

export default SignIn;