import * as React from 'react';
import User from './User';
import CustomXGridContainer from './components/CustomXGridContainer';
import CustomXGridTheme from './components/CustomXGridContainer/Theme';
import SkillDbCollections from './SkillDbCollections'
const { skills, skillsSet, families } = SkillDbCollections;

class TagsGrid extends CustomXGridContainer {
  constructor(props) {
    super(props);
    
    this.state = {
      width: window.innerWidth,
      filterModel: [],
      skills: skillsSet,
      gridData: {
        columns:[],
        rows: []
      }
    };
  }  
  
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);

    new User().load().then(user => {
      this.setState({user: user});
    });
    const data = this.getData();
    this.setState({
      gridData: data
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  
  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }
  
  getData() {
    return {
      columns: [
        {field: 'id', headerName: 'Id', hide: true},
        {field: 'tag', headerName: 'Tag', width: 250, renderCell: this.renderTag},
        {field: 'skills', headerName: "Related Skills", flex: 1, width: 200, renderCell: this.renderSkills}
      ],
      rows: this.getRows()
    }
  }
  
  getRows() {    
    return Object.keys(families).sort().map((family, index) => {
      const filtered = skills.filter(s => s.public && s.hasOwnProperty('families') && s.families.includes(family)).sort();

      return {
        id: index,
        tag: this.titleCase(family),        
        skills: filtered.map(s => this.state.skills[s._id].name),
        tagRawValue: family
      };
    });
  }

  cellClicked = ()=> {}

  renderTag = (params) => {
    return (
      <div className="fullHeight" style={{display: 'inline-block'}}>
        <a href={`/tag/${encodeURIComponent(params.row.tagRawValue)}`}>{params.value}</a>
      </div>
    )
  }

  render() {
    return (
      <div style={{width: '100%' }}>
        {this.renderGrid(this.state.gridData, this.cellClicked, this.state.filterModel)}        
      </div>
    );
  }

  renderSkills = (params) => {

    return <div className="fullHeight">
      {
        params.value.map((skillName, index) => 
          <div style={{
              float: 'left'
            }}
            key={"skillcontainer"+index}
          >
            <a href={`/skill/${skillName}`}>{this.titleCase(skillName)}</a>&nbsp; &nbsp;
          </div>
        )
      }
    </div>
  }

}

export default CustomXGridTheme(TagsGrid);