import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import './utilities';

import skills from './skills.json';
import categories from './skill-categories.json';
import convertArrayToObject from './utilities';

import Proficiency from './Proficiency';

class Recommendations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      grouped: convertArrayToObject(skills, '_id')
    };
  }

  getNumSkillsCategory(array, category) {
    return array.filter(a => this.state.grouped[a] && this.state.grouped[a].categories && this.state.grouped[a].categories.indexOf(category) !== -1).length;
  }

  selectSkillsForCategory(category) {
    const selected = this.props.user.data.favorites.items.filter(f => this.state.grouped[f.skillId] && this.state.grouped[f.skillId].categories && this.state.grouped[f.skillId].categories.indexOf(category) !== -1);
    const mastery = {};
    selected.forEach(s => {
      const mastered = this.props.user.data.mastery.items.find(i => i.skillId === s.skillId);
      mastery[s.skillId] = mastered ? mastered.proficiency : 0;
    });

    return mastery;
  }

  renderFromSelectedCategory(category) {
    const mastery = this.selectSkillsForCategory(category);

    let final = [];
    switch (this.props.user.data.learningMethod) {
      case "weaknesses":
        final = Object.keys(mastery).filter(m => mastery[m] === Math.min(...Object.values(mastery)));
        break;
      case "strengths":
        final = Object.keys(mastery).filter(m => mastery[m] === Math.max(...Object.values(mastery).filter(m => m < 3)));
        break;
      default:
        final = Object.keys(mastery);
        break;
    }

    return final.map(s => (
      <div key={s}>
        <Proficiency user={this.props.user} skillId={s} readOnly={true} /> <a href={`/skill/${this.state.grouped[s].name}`}>{this.state.grouped[s].name}</a>
      </div>
    ));
  }

  renderGeneralist(proficiencyCount) {
    if (!this.props.user) return this.renderSkeleton();
    if (this.props.user.data.archetype !== 'generalist') return "";

    const sorted = Object.keys(proficiencyCount).sort((a, b) => proficiencyCount[a] - proficiencyCount[b]);

    return this.renderFromSelectedCategory(sorted[0]);
  }

  renderSpecialist(proficiencyCount) {
    if (!this.props.user) return this.renderSkeleton();
    if (this.props.user.data.archetype !== 'specialist') return "";

    const sorted = Object.keys(proficiencyCount).sort((a, b) => proficiencyCount[b] - proficiencyCount[a]);

    return this.renderFromSelectedCategory(sorted[0]);
  }

  renderPolymath(proficiencyCount) {
    if (!this.props.user) return this.renderSkeleton();
    if (this.props.user.data.archetype !== 'polymath') return "";

    const worst = Object.keys(proficiencyCount).sort((a, b) => proficiencyCount[a] - proficiencyCount[b]);
    const best = Object.keys(proficiencyCount).sort((a, b) => proficiencyCount[b] - proficiencyCount[a]);

    return (
      <div>
        {this.renderFromSelectedCategory(worst[0])}
        {this.renderFromSelectedCategory(best[0])}
      </div>
    )
  }

  render() {
    const proficiencyCount = {};
    if (this.props.user) {
      Object.keys(categories)
        .filter(c => categories[c].isActive)
        .forEach(c => proficiencyCount[c] = this.getNumSkillsCategory(this.props.user.data.mastery.items.map(i => i.skillId), c));
    }

    return (
      <div id="recommendations" className="dashboard-card">
        <h2 className="dashboard-title">Recommendations</h2>
        <p>
          <em>For optimal results, take 5-10 minutes to favorite skills and add proficiency to skills you already know from <a href="/index">this list</a>. Also, update your preferences above.</em>
        </p>
        {this.renderGeneralist(proficiencyCount)}
        {this.renderSpecialist(proficiencyCount)}
        {this.renderPolymath(proficiencyCount)}
      </div>
    );
  }

  renderSkeleton() {
    <div>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  }
}

export default Recommendations;