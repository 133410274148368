/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      username
      archetype
      learningMethod
      chronotype
      createdAt
      updatedAt
      mastery {
        items {
          id
          userID
          skillId
          proficiency
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      favorites {
        items {
          id
          userID
          skillId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listUserDatas = /* GraphQL */ `
  query ListUserDatas(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        archetype
        learningMethod
        chronotype
        createdAt
        updatedAt
        mastery {
          nextToken
        }
        favorites {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getUserFavorite = /* GraphQL */ `
  query GetUserFavorite($id: ID!) {
    getUserFavorite(id: $id) {
      id
      userID
      skillId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserFavorites = /* GraphQL */ `
  query ListUserFavorites(
    $filter: ModelUserFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        skillId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUserMastery = /* GraphQL */ `
  query GetUserMastery($id: ID!) {
    getUserMastery(id: $id) {
      id
      userID
      skillId
      proficiency
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserMasterys = /* GraphQL */ `
  query ListUserMasterys(
    $filter: ModelUserMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMasterys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        skillId
        proficiency
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getJobs = /* GraphQL */ `
  query GetJobs($id: ID!) {
    getJobs(id: $id) {
      id
      business
      url
      date
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listJobss = /* GraphQL */ `
  query ListJobss(
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        business
        url
        date
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        archetype
        learningMethod
        chronotype
        createdAt
        updatedAt
        mastery {
          nextToken
        }
        favorites {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
