import React from 'react';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import Header from '../../components/Header';

class SignOut extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <AmplifySignOut />
      </div>
    )
  }
}

export default SignOut;