import React from 'react';

import Header from '../../components/Header';
import SearchBar from '../../SearchBar.js';

class Tools extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="v-padding30">
            <h1>SkillUp eJournal</h1>
            <span><strong>Price: $27</strong></span>
            <p>The SkillUp eJournal helps you guide your reflection and log your progress when learning a skill. You can use the tool for as many skills as you want and for any period you plan on learning the skill.</p>
            <button><a href="https://skillupedia.podia.com/skillup-ejournal" target="_blank" rel="noopener noreferrer">More about the SkillUp eJournal</a></button>
          </div>
          <div className="v-padding30">
            <h1>Reflection Journal</h1>
            <span><strong>Price: FREE</strong></span>
            <p>The Reflection Journal is a free tool provided by SkillUp Academy. For each skill that you learn, we strongly recommend you spend time to reflect on your progress. For most skills, you’ll want to do that daily, weekly, and monthly.</p>
            <button><a href='/me/reflection'>Check it out</a></button>
          </div>
          <br />
        </div>
        <hr />
        <div className="content-wrapper">
          <SearchBar />
        </div>
      </div>
    )
  }
}

export default Tools;