class BusinessSkillsSearch {

  constructor(props) {
    this.getData = props.getData;
  }

  getResult = (searchTerm) => {
    if(!this.skills) {
      this.skills = this.getData();
    }

    let data = this.skills && this.skills.filter(
      entry => entry[0].toLowerCase().includes( searchTerm.toLowerCase() )
    );

    return data;
  }

  searchAnchorrefs = []

  goToSkill = (skill) => {
    window.location.href = `/skill/${skill[0]}`;
  }

  getModal = (props) => {

    let {selectionIndex,index, s} = props

    return (
      <li
        className="pointer"
        style={{
          backgroundColor: index === selectionIndex ? "#EBEDEF": "white"
        }}
        onClick={ this.goToSkill && this.goToSkill.bind(this, s)}
        key={"skill-job-row-"+index}
      >
        <a className="orangeColor"
          ref={ x => this.searchAnchorrefs[index] = x }
          href={`/skill/${s[0]}`}
        >
          {s[0]}
        </a> <span>( {s[1]} )</span>
      </li> 
    );
  }

}

export default BusinessSkillsSearch; 