import React from 'react';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import queryString from 'query-string';
import BusinessTabs from './BusinessTabs';
import OpenPositionsTab from './OpenPositionsTab';
import SkillsNeededTab from './SkillsNeededTab';
import BusinessDataTab from './BusinessDataTab';
import DatePickerFn from '../DatePicker';
import skills from '../../skills.json';
import Header from '../Header';
import convertArrayToObject from '../../utilities';

const NETVIEWER_API_URL = process.env.NODE_ENV === 'development' ? 'https://93l3o3musg.execute-api.us-east-1.amazonaws.com/dev' : 'https://l7st1kuto2.execute-api.us-east-1.amazonaws.com/prod';

class Business extends React.Component {
  constructor(props) {
    super(props);

    const qs = queryString.parse(this.props.location.search);

    this.state = {
      apiKey: qs.apiKey? qs.apiKey : false,
      groupedSkills: convertArrayToObject(skills, '_id'),
      source: 'openPositions'
    };
  }

  componentDidMount() {
    this.fetchDataFromServer(null)
  }

  fetchDataFromServer = (date = new Date()) => {
    const { match: { params } } = this.props;
    const apiUrl = date ? `${NETVIEWER_API_URL}/business/${params.business}/date/${date.toISOString().split('T')[0]}` : `${NETVIEWER_API_URL}/business/${params.business}`;

    axios.get(apiUrl, params).then((response) => {
      const data = response.data.data;
      this.setState({
        data: data.jobs,
        logo: data.logo
      });
    })
  }

  getData = () => {
    return this.state.data || null;
  }

  getGroupedSkills = () => {
    return this.state.groupedSkills || {};
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  orderedSkills = () => {
    console.log('orderedSkills')
    if (!this.state.data) return [];
    
    const allSkills = this.state.data.map(job => this.getSkills(job.skills)).filter(l => l !== '');
    const reducer = (a, c) => { a[c] = (a[c] || 0) + 1; return a};
    const groupedSkills = allSkills.flat(1).reduce(reducer, Object.create(null));

    return Object.entries(groupedSkills).sort((a, b) => b[1]-a[1]);
  }

  getSkills(jobSkills) {
    return jobSkills.filter(js => this.state.groupedSkills.hasOwnProperty(js)).map(js => this.state.groupedSkills[js].name);
  }

  orderedDepartments() {
    const departments = {};
    this.state.data.forEach(j => {
      if (j.hasOwnProperty('department')) {
        const department = j.department.replace("Department ", "");
        departments.hasOwnProperty(department) ? departments[department]++ : departments[department] = 1;
      }
    });

    return Object.entries(departments).sort((a, b) => b[1]-a[1]);
  }

  ordered = (key) => {
    const data = {};
    this.state.data.forEach(j => {
      if (j.hasOwnProperty(key)) {
        const trimmed = j[key].replace(`${this.titleCase(key)} `, "");
        data.hasOwnProperty(trimmed) ? data[trimmed]++ : data[trimmed] = 1;
      }
    });

    return Object.entries(data).sort((a, b) => b[1]-a[1]);
  }

  onFilterUpdated = (filter) => {
    this.setState({source: filter})
  }

  handleDateChanged = (date) => {
    this.fetchDataFromServer(date);
  }
  
  render() {
    return (
      <div className="light-grey-bg">
        <Header />
        <div className="content-wrapper">
          {this.renderLogoOrName()}
        </div>
        <br />
        <center>
          <BusinessTabs 
            source={this.state.source} 
            onFilterUpdated={this.onFilterUpdated.bind(this)} 
          />
          <br />
          <div>
            <span>Choose date: </span>
            <div style={{display: 'inline-block'}}>
              <DatePickerFn handleDateChanged={this.handleDateChanged.bind(this)} />
            </div>
          </div>
        </center>
        
        {
          this.state.source === 'openPositions' && 
          <OpenPositionsTab 
            apiKey={this.state.apiKey} 
            getData = {this.getData}
            getGroupedSkills = {this.getGroupedSkills}
            renderSkeleton = {this.renderSkeleton}
            ordered = {this.ordered}
          />
        }
        {
          this.state.source === 'skillsNeeded' && 
          <SkillsNeededTab 
            apiKey={this.state.apiKey}
            data = {this.state.data}
            renderSkeleton = {this.renderSkeleton}
            orderedSkills = {this.orderedSkills}
          />
        }
        {
          this.state.source === 'businessData' && 
          <BusinessDataTab business={this.titleCase(this.props.match.params.business)} />
        }

        
        
        {/* <div className="content-wrapper"><GoogleMapsEmbed /></div> */}
      </div>
    )
  }

  renderLogoOrName = () => {
    if (!this.state.logo) {
      return <h1>{this.titleCase(this.props.match.params.business)}</h1>;
    } else {
      return <img src={this.state.logo} alt={`${this.titleCase(this.props.match.params.business)} logo`} style={{width: '80vw', maxWidth: '300px'}} />
    }
  }

  renderSkeleton = () => {
    return (
      <div>
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
      </div>
    )
  }
}

export default Business;