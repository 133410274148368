import React from 'react';
import SearchBarBasic from './SearchBarBasic';

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.isSkillUp = window.location.hostname.includes('skillup');
    this.siteTitle = this.isSkillUp ? "SkillUp Academy" : "Skills DB";

    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  hideHeader() {
    return this.isSkillUp ? false : true;
  }

  showLogo() {
    return this.isSkillUp ? false : true;
  }

  render() {
    return (
      <div>
        <div className="content-wrapper" style={{paddingTop: 'calc(80vmax / 10)', paddingBottom: 'calc(80vmax / 10)'}}>
          <div className="content" style={{width: '83vw', height: "78vh"}}>
            <div style={{position: 'relative', top: this.isSkillUp ? '25vh' : '-100px'}}>
              {
                this.showLogo() &&
                  <center>
                    {
                      this.state.width < 850 &&
                        <div style={{paddingTop: '25vh'}} />
                    }
                    <img src={process.env.PUBLIC_URL + '/logo-skillsdb.png'} alt="Skills DB" style={{width: '50vw'}} />
                  </center>
              }
              <SearchBarBasic openSkillpage={true} theme={this.isSkillUp ? 'white': ''} hideHeader={this.hideHeader()} />
              <br />
              <center>
                <a href="/explore?source=skills">Browse All Skills</a>&nbsp;&nbsp;
                <a href="/explore?source=jobs">Browse All Jobs</a>
                {/* <a href="/categories">Browse Categories</a> */}
              </center>
            </div>
          </div>
          <div className="content-wrapper">
            <center>
              <h2>Stop looking, start finding.</h2>
              <h3>{this.siteTitle} is the most comprehensive database of skills meta data</h3>
            </center>
            <p>Browse and search our ever-growing database of skills to learn anything you want to know about a skill including: related skills, benefits of learning it, what type of intelligence is involved, trending information, relevant jobs, a list of top curated external resources, and more.</p>
            <p>To take your learning to the next level, check out our <a href="/courses">courses</a>.</p>
            
            <br />

            {/* <div>
              <h2>Features</h2>
              <div style={{width: '800px', maxWidth:"93%"}}>
                <h3>Interactive Skill Trees</h3>
                <Iframe url="https://ember.ly/sapling/45cc4ce1ffd24c10a61aabc0e0458867/5fb51e4bdb4b65f899cc00ed?access=public&embed=true"
                  width="100%"
                  height="600px"
                  frameBorder="0" />
              </div>
              <div style={{width: '800px', maxWidth:"93%"}}>
                <h3>Visual Plans</h3>
                <Iframe url="https://www.mindmeister.com/maps/public_map_shell/1695910764/writing-on-medium-the-first-1000-hours?width=600&height=400&z=auto&t=HNgBhboTVu&live_update=1"
                  width="100%"
                  height="600px"
                  frameBorder="0" />
              </div>
              <br /><br />
              <h4>And more, including Trainers, Tools, Courses, Books, Articles, Videos, Websites, Communities, Checklists, and Journals!</h4>
            </div> */}

          </div>
        </div>
        <div className="dark-section">
          <div className="content-wrapper">
            <div className="content-wrapper">
              <center><h2>Learn skills like never before</h2></center>
              <div className="content-wrapper">
                <p>❌ No more endless searching for good resources</p>
                <p>❌ No more dabbling and having no clue what you’re doing</p>
                <p>❌ No more questions about your next steps</p>
              </div>
              <br />
              <center>
                <h3>We have what you’re looking for!</h3>
                <br />
                <a href="/explore?source=skills"><button className='bg-orange'>Browse Catalog</button></a>
              </center>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="content-wrapper">
            <h2>Founder’s Story</h2>
            <p>Three years ago, I was not very skilled. Or rather, I was very good at two things—Programming and Farming. That’s what I had done for most of my life. But I knew there was more to life than just these two things. </p>
            <p>So I embarked on a journey to skill up!</p>
            <p>I started researching methods for learning. I read tons of books and articles, and watched countless videos. But most importantly, I practiced daily.</p>
            <p>That’s when, in October 2017, I decided I would start learning three new skills every month. As you can imagine, the experience had tons of ups and downs. But what’s important is the result of all that experimentation.</p>
            <p>Since January 2018, I’ve been writing about my experiences as a nomadic learner on Medium.com. I’ve remained a top writer in many categories since then, including top #1 in Entrepreneurship and Education.</p>
            <img width="95%" alt="Danny Forest, Status quo's worst nightmare | Obsessive learner | Productivity beast. Get in touch: https://forestco.co" src="https://images.squarespace-cdn.com/content/v1/5ea9d74b605c145d86db6eb1/1588247862951-QBEQHY1QWIUQZZHG687S/ke17ZwdGBToddI8pDm48kB-9iqnwQdfWvfNh9wOd29UUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcLD4ed49dQmkxzbnq-Pb-v0UzhtBnCA9cLXccUGBxbNG545HHBsZfExWPA8A59GJs/Screen+Shot+2020-04-20+at+4.49.06+PM.png" />
            <p>My content reaches 300,000+ people every month, and that’s only on Medium.</p>
            <img width="95%" alt="327,780 Views (30 days)" src="https://images.squarespace-cdn.com/content/v1/5ea9d74b605c145d86db6eb1/1588247885639-5AQFC0J0U5VQJQQHGZLQ/ke17ZwdGBToddI8pDm48kLNZzdYPWHLeXbtrIuw_Z6QUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dg6nl6e6GKsp_SDWtlrz35SXP3lh-Y-2ADqmzT22bQKuCjLISwBs8eEdxAxTptZAUg/Screen+Shot+2020-04-20+at+5.27.35+PM.png" />
            <p>If you knew the “me” from 3 years ago, you would never have guessed I’d be where I am today. I credit a lot of this success to my dedication to learning new skills.</p>
            <p>I’m nothing special. What I’ve accomplished can be achieved by you as well. And this can all be facilitated thanks to {this.siteTitle}.</p>
          </div>
        </div>
        <div className="orange-section" style={{padding: '50px 0px 50px 0px'}}>
          <center>
            <h2>Ready to learn?</h2>
          </center>
        </div>
        <div>
          <div className="content-wrapper">
            <div className="content-wrapper">
              <SearchBarBasic openSkillpage={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;